import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Component from '@ember/component';
import config from '../config/environment';
import videojs from 'videojs';

export default Component.extend({

  host: config.apiEndpoint,

  appState: service(),

  size: '',

  idsuffix: '',

  attributeBindings: ['data-mh'],

  classNameBindings: ['active'],

  medium: computed('links', 'index', 'link', function(/* name, oldvalue, value */) {
    let medium = null;
    let link = this.link;
    if (!link && this.links && this.links.content && (typeof this.index === "number")) {
      link = this.links.content.objectAt(this.index);
    }
    if (link) {
      medium = link.get('medium');
    }
    return medium;
  }),

  href: computed('medium', 'medium.base64data', function() {
    const base64data = this.get('medium.base64data');
    if (base64data) {
      return base64data;
    }
    const host = this.host;
    const locale = this.get('appState.localePrefix');
    const size = this.size;
    let mediumId = '_';
    if (this.medium) {
      mediumId = this.get('medium.id');
    }
    const mime = this.get('medium.mime');
    if (mediumId && mime) {
      const gravity = this.get('medium.gravity') || this.defaultGravity;
      const extension = (!this.thumbnail && mime.indexOf("video")) === 0 ? '.flv' :
        (this.thumbnail && mime.indexOf("video") === 0 ? '.jpg' :
          ((mime.indexOf('image/jp') < 0)
            ? '.' + mime.substring(mime.indexOf('image/') + 6) : '.jpg'));
      var params = gravity ? `?gravity=${gravity}` : '';
      return `${host}/${locale}/media/${size}${mediumId}${extension}${params}`;
    } else {
      return null;
    }
  }),

  hrefThumbnail: computed('medium', function() {
    const host = this.host;
    const locale = this.get('appState.localePrefix');
    const size = this.size;
    let mediumId = '_';
    if (this.medium) {
      mediumId = this.medium.get('id');
    }
    const extension = '.jpg';
    return `${host}/${locale}/media/${size}${mediumId}${extension}`;
  }),

  isImage: computed('medium', 'medium.base64data', function() {
    const mime = this.get('medium.mime');
    const base64data = this.get('medium.base64data');
    return base64data || (mime && mime.indexOf("image") === 0);
  }),

  isVideo: computed('medium', function() {
    const mime = this.get('medium.mime');
    return !this.thumbnail && mime && mime.indexOf("video") === 0;
  }),

  didRender: function () {
    if (this.medium) {
      let elmtId = 'video-' + this.medium.get('id') + this.idsuffix;
      if (this.player) {
        if (this.player.id_ === elmtId) {
          return;
        } else {
          this.player.dispose();
        }
      }
      if (this.isVideo) {
        this.player = videojs(elmtId, {
          techOrder: ['html5'],
          flvjs: {
            mediaDataSource: {
              isLive: true,
              cors: true,
              withCredentials: false,
            },
            // config: {},
          },
        });
      }
    }
  }
});
