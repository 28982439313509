import $ from 'jquery';
import { inject as service } from '@ember/service';
import EmberObject, { computed } from '@ember/object';
import BaseModalComponent from './modal-base';

export default BaseModalComponent.extend ({

  ajax: service(),

  session: service(),

  appState: service(),

  attributeBindings: ['aria-labelledby', 'role', 'aria-hidden', 'tabindex'],

  actions: {
    change() {
      var self = this;
      var locale = this.get('appState.localePrefix');
      this.set('error', undefined);
      var pdata = this.passwordUpdate;
      this.ajax.request(`/${locale}/password_update.json`, {
        type: 'PUT',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          'password_update': pdata
        })
      }).then(function(/* response */) {
        $(self.element).modal('hide');
        self.clean();
      }, function(err) {
        this.set('error', err);
      });
    }
  },

  didReceiveAttrs() {
    this.clean();
  },

  clean() {
    this.set('passwordUpdate', EmberObject.create({
      'oldpass': ''
    }));
    this.set('passwordUpdate.user_attributes', EmberObject.create({
      'password': '',
      'password_confirmation': ''
    }));
  },

  unlessPasswordMatches: computed('passwordUpdate.user_attributes.{password,password_confirmation}', function() {
      return this.get('passwordUpdate.user_attributes.password') !==
        this.get('passwordUpdate.user_attributes.password_confirmation');
  })

});
