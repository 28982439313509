import { run } from '@ember/runloop';
import { Promise, resolve } from 'rsvp';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Base from 'ember-simple-auth/authenticators/base';
import config from '../config/environment';

export default Base.extend({

  ajax: service(),

  tokenEndpoint: config['ember-simple-auth'].tokenEndpoint,

  restore: function(data) {
    return new Promise((resolve, reject) => {
      if (!isEmpty(data.user)) {
        resolve(data);
      } else {
        reject();
      }
    });
  },

  authenticate(options) {
    return new Promise((resolve, reject) => {
      return this.ajax.post(this.tokenEndpoint, {
        data: {
          login : {
            login: options.identification,
            client_short: options.clientshort,
            password: options.password
          }
        }
      }).then((response) => {
        run(() => {
          if (response.client) {
            resolve({
              user: response
            });
          } else {
            reject(response.message);
          }
        });
      }, (xhr/* , status, error */) => {
        let response = xhr.responseText;
        run(() => {
          reject(response);
        });
      });
    });
  },

  invalidate() {
    return resolve();
  }
});
