import AbstractRoute from '../../abstract';
import Transition from "@ember/routing/-private/transition";
import AdminParameterController from "mikrobi-responsive/controllers/admin/parameters/parameter";
import Parameter from "mikrobi-responsive/models/parameter";

export default class AdminParameterRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.parameter_id === 'new') {
      return this.store.createRecord('parameter');
    }
    return this.store.findRecord('parameter', params.parameter_id, { reload: true });
  }

  setupController(controller: AdminParameterController, model: Parameter, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model);
  }

}
