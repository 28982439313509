import EmberObject from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AnalysisRoute extends Route {
  @service session;

  theModel = null;

  constructor() {
    super(...arguments);
    this.theModel = EmberObject.create();
  }

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
    return super.beforeModel(transition);
  }

  model(params) {
    var normalized_client = this.store.normalize(
      'client',
      this.session.data.authenticated.user.client
    );
    this.store.push({ data: normalized_client.data });
    for (
      var i = 0;
      normalized_client.included && i < normalized_client.included.length;
      i++
    ) {
      this.store.push({ data: normalized_client.included[i] });
    }
    var roles = this.session.data.authenticated.user.user_roles;
    for (var j = 0; j < roles.length; j++) {
      this.store.push(this.store.normalize('user-role', roles[j]));
    }

    var location;
    if (params.location_id && params.location_id !== '_') {
      location = this.store.peekRecord('sampling-location', params.location_id);
    }

    var model = this.theModel;
    model.set('location', location);
    this.store.findAll('occurrence-type').then(function (result) {
      model.set('occurrenceTypes', result);
    });
    this.store.findAll('attribute-type').then(function (result) {
      model.set('attributeTypes', result);
    });
    this.store.findAll('phylum').then(function (result) {
      model.set('phylums', result);
    });
    this.store.findAll('parameter').then(function (result) {
      model.set('parameters', result);
    });
    this.store.findAll('chart').then(function (result) {
      model.set('charts', result);
    });
    /*
    return Ember.RSVP.hash({
      location: location,
      occurrenceTypes: this.store.findAll('occurrence-type'),
      attributeTypes: this.store.findAll('attribute-type'),
      phylums: this.store.findAll('phylum'),
      parameters: this.store.findAll('parameter'),
      charts: this.store.findAll('chart')
    });
    */
    return model;
  }
}
