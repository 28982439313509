import JSONSerializer from './application';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default JSONSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    sites: {
      deserialize: 'records',
      serialize: 'records',
    },
    users: {
      deserialize: 'records',
    },
  },
});
