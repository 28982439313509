import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from "@glimmer/tracking";
import DS from "ember-data";
import Site from "mikrobi-responsive/models/site";

export default class ClientListComponent extends Component {

  @tracked declare filterTerm:string;
  @tracked declare sites:DS.PromiseArray<Site>;

  get filteredSites() {
    let filterTerm = this.filterTerm;
    return this.sites.filter(function(site) {
      return !filterTerm || site.name.toLowerCase().indexOf(filterTerm.toLowerCase())>-1;
    });
  }

  @action
  clearFilterTerm() {
    this.filterTerm = "";
  }

}
