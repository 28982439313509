import { inject as service } from '@ember/service';
import $ from 'jquery';
import Controller from '@ember/controller';

export default Controller.extend({

  store: service(),

  actions: {
    modal(medium, selector, action, event) {
      event.preventDefault();
      this.set('currentMedium', medium);
      $(selector).modal(action);
      event.stopPropagation();
    },

    createMedium() {
      this.set('currentMedium', this.store.createRecord('medium'));
    }
  }

});
