import AbstractRoute from '../../abstract';

export default class AdminClientsClientRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.client_id === 'new') {
      return this.store.createRecord('client');
    }
    return this.store.findRecord('client', params.client_id, { reload: true });
  }
}
