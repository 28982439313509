import JSONSerializer from './application';

export default JSONSerializer.extend({
  attrs: {
    createdAt: { serialize: false },
    updatedAt: { serialize: false },
    organismOccurrences: {
      deserialize: 'records',
      serialize: 'rails'
    },
    sampleAttributes: {
      deserialize: 'records',
      serialize: 'rails'
    },
    mediumLinks: {
      deserialize: 'records',
      serialize: 'rails'
    },
    analysis: {
      serialize: false
    }
  }
});
