import AbstractRoute from '../abstract';
import DS from 'ember-data';
import Client from "mikrobi-responsive/models/client";

export default class AdminClientsClientRoute extends AbstractRoute {
  model(/* params */) {
    if (this.permissions.isSuperAdministrator()) {
      return this.store.findAll('client');
    } else {
      return DS.PromiseArray.create({
        promise: this.store
          .findRecord('client', this.session.data.authenticated.user.client.id)
          .then((client: Client) => {
            return [client];
          }),
      });
    }
  }
}
