import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({

  store: service(),

  attributeBindings: ['data-toggle', 'data-target'],

  didReceiveAttrs() {
    if (!this.link) {
      var link = this.store.createRecord('medium-link');
      var medium = this.store.createRecord('medium');
      link.set('medium', medium);
      this.set('link', link);
    }
  }
});
