import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({

  classNameBindings: ['isHovered:hovered'],

  isHovered: computed('hoveredAttributeValue', function() {
    return !!this.hoveredAttributeValue && this.get('hoveredAttributeValue.mediumLinks.length')>0;
  }),

  didReceiveAttrs() {
  },

  actions: {
    onAttributeValueEnter(attributeValue) {
      this.set('hoveredAttributeValue', attributeValue);
    },
    onAttributeValueLeave() {
      this.set('hoveredAttributeValue', undefined);
    }
  },

  typeValues: computed('attributeType', 'sample.sampleAttributes', function() {
    var attrType = this.attributeType;
    return this.get('sample.sampleAttributes')
      .filter(function(attr) {
        return attr.get('attributeValue.attributeType.id') === attrType.get('id');
      });
  }),

  selectedValues: computed('sample.sampleAttributes.@each.dirtyType', 'typeValues', function() {
    var sv = this.typeValues
      .filter(function(attr) {
        return !attr.get('isDeleted');
      });
    return sv;
  }),

  aggregatedValue: computed('selectedValues', function() {
    return this.selectedValues
      .map(function(attr) {
        return attr.get('attributeValue.label');
      })
      .join(", ");
  }),

  sampleAttributesByValueId: computed('attributeType', 'typeValues', function() {
    let typeValues = this.typeValues;
    return this.get('attributeType.attributeValues').reduce(function(map, value) {
      var sampleAttribute = typeValues.find(function(sampleAttribute) {
        return sampleAttribute.get('attributeValue.id') === value.get('id');
      });
      if (sampleAttribute) {
        map[value.get('id')] = sampleAttribute;
      }
      return map;
    }, {});
  })
});
