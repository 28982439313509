import Component from '@ember/component';
import jQuery from 'jquery';

export default Component.extend({
  attributeBindings: ['aria-labelledby', 'role'],
  classNameBindings: ['phylum:active'],
  actions: {
    setPhylum(phylum) {
      jQuery('.dropdown-toggle', this.element).dropdown('toggle');
      this.set('phylum', phylum);
      this.set('common', false);
      this.set('inSample', false);
    },
    setInSample() {
      jQuery('.dropdown-toggle', this.element).dropdown('toggle');
      this.set('phylum', null);
      this.set('common', false);
      this.set('inSample', true);
    },
    setCommon() {
      this.set('common', true);
      this.set('inSample', false);
      jQuery('.dropdown-toggle', this.element).dropdown('toggle');
    }
  }
});
