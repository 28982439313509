import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Transition from "@ember/routing/-private/transition";

export default class IndexRoute extends Route {

  @service declare session: any;

  beforeModel(transition: Transition): any {
    this.session.requireAuthentication(transition, 'login');
  }

  afterModel(/*resolvedModel: any, transition: Transition*/): any {
    this.transitionTo('analysis-all');
  }
}
