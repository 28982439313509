import JSONSerializer from './application';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default JSONSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    samplingLocations: {
      deserialize: 'records',
      serialize: 'records',
    },
  },
});
