import Model, { attr, hasMany } from '@ember-data/model';
import DS from "ember-data";
import Site from "mikrobi-responsive/models/site";
import User from "./user";

export default class Client extends Model {
  @attr('date') declare createdAt: Date;
  @attr() declare name: string;
  @attr() declare short: string;
  @attr() declare address1: string;
  @attr() declare address2: string;
  @attr() declare address3: string;
  @attr() declare address4: string;
  @attr('date') declare updatedAt: Date;
  @hasMany('user') declare users: DS.PromiseArray<User>;
  @hasMany('site') declare sites: DS.PromiseArray<Site>;
}
