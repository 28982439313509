import Helper from '@ember/component/helper';
import moment from 'moment';

export function formatDatetime(params, hash) {
  var date = params[0];
  var format = hash.format;
  return moment(date).format(format);
}

export default Helper.helper(formatDatetime);
