import ApplicationAdapter from "./application";
import { inject as service } from "@ember/service";
import AppStateService from "mikrobi-responsive/services/app-state";

export default class UserAdapter extends ApplicationAdapter {

  @service declare appState: AppStateService;

  /**
   Creates a path for the model like `de-DE${prefix}/${clientPrefix}${path}`
   where
   path: is the value returned by the _super() method
   prefix: is the value of modelName.prefix if modelName is an object and has this property - in this case modelName
   is set to modelName.modelName
   clientPrefix: is clients/${clientId} from the session for non-base data
   @method pathForType
   @param {String} modelName
   @return {String} path
   **/
  pathForType(modelName: any, snapshot: any) {
    if (snapshot === undefined) {
      return super.pathForType(modelName);
    }
    var locale = this.appState.localePrefix;
    var clientId = snapshot.record.client.get('id');
    return `${locale}/clients/${clientId}/users`;
  }

}
