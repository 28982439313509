import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import PermissionService from "mikrobi-responsive/services/permissions";

export default class HasRoleHelper extends Helper {

  @service declare permissions: PermissionService;

  compute(params: String[] /*, hash */) {
    let permissions = this.permissions;
    return permissions.hasRole(params);
  }
}
