import Controller from "@ember/controller";
import Site from "mikrobi-responsive/models/site";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import SamplingLocation from "mikrobi-responsive/models/sampling-location";
import Client from "mikrobi-responsive/models/client";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import { inject as service } from "@ember/service";
import AppStateService from "mikrobi-responsive/services/app-state";
import IntlService from "ember-intl/services/intl";


class SiteForm extends FormDataProxy<Site> {
  @tracked samplingLocations: Array<SamplingLocationForm>;

  constructor(model: Site) {
    super(model, undefined);

    this.samplingLocations = model.samplingLocations.map(function(item) {
      return new SamplingLocationForm(item);
    });

    this.registerChildren(this.samplingLocations);
  }

  addSamplingLocation(location: SamplingLocationForm): void {
    this.registerChild(location);
    this.samplingLocations.addObject(location);
  }
}

class SamplingLocationForm extends FormDataProxy<SamplingLocation> {
  constructor(model: SamplingLocation) {
    super(model, undefined);
  }
}

export default class SiteController extends Controller {

  @service declare appState: AppStateService;
  @service declare intl: IntlService;
  @tracked declare formData: SiteForm;
  @tracked declare client: Client;

  prepareFormData(model: Site, client: Client) {
    this.formData = new SiteForm(model);
    this.client = client;
  }

  @action
  createLocation() {
    const location = this.store.createRecord('sampling-location');
    location.site = this.model;
    this.formData.addSamplingLocation(new SamplingLocationForm(location));
  }

  @action
  save() {
    this.formData.save(false);

    this.appState.dismissLoadingInfo(undefined);

    const self = this;
    let callback = this.appState.addLoading(
      'info',
      this.intl.t('messages.savingsite'),
      true
    );


    return this.client.save()
      .then((client: Client) => {
        callback();
        client.sites.removeObjects(client.sites.filter((site: Site) => {
          return site.get('id') === null;
        }));
        return client.sites.reduce((prev: Site, current: Site) => {
          console.log(current.get('id') + " - " + current.name);
          if (current.name === self.model.name) {
            if (prev && prev.createdAt>current.createdAt) {
              return prev;
            } else {
              return current;
            }
          } else {
            return prev;
          }
        });
      }, () => callback)
      .then((site: Site) => {
        self.replaceRoute("admin.clients.client.sites.site", site.client, site);
      });
  }
}
