import Service from '@ember/service';
import EmberObject, { observer, computed } from '@ember/object';

export default Service.extend({

  init() {
    this._super(...arguments);
    this.set('analysis', EmberObject.create());
  },

  previousSampleObserver: observer('analysis.sample', function(target, name, value) {
    if (this.get('analysis.sample_previous') !== value) {
      this.set('previousSample', value);
    }
  }),

  previousSample: computed('analysis.{sample_previous,sample}', {
    get() {
      return this.get('analysis.sample_previous') || this.get('analysis.sample');
    },
    set(key, value) {
      this.set('analysis.sample_previous', value);
    }
  })

});
