import Model, { attr, hasMany } from "@ember-data/model";
import DS from "ember-data";
import AttributeValue from "mikrobi-responsive/models/attribute-value";

export default class AttributeType extends Model {
  @attr() declare varname: string;
  @attr('boolean') declare multiple: boolean;
  @attr() declare description: string;
  @attr() declare name: string;
  @hasMany('attribute-value') declare attributeValues: DS.PromiseArray<AttributeValue>;
}
