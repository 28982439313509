import AbstractAdminRoute from '../../../../abstract';

export default class UserRoute extends AbstractAdminRoute {
  model(params) {
    if (params.user_id === 'new') {
      const user = this.store.createRecord('user');
      return user;
    }
    return this.store.peekRecord('user', params.user_id);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.clearUpdates();
    controller.initUserRoles();

    const client = this.modelFor('admin.clients.client');
    controller.client = client;
  }
}
