import Component from '@ember/component';
import { action } from '@ember/object';
import { tracked } from "@glimmer/tracking";
import DS from "ember-data";
import Client from "mikrobi-responsive/models/client";

export default class ClientListComponent extends Component {

  @tracked declare filterTerm:string;
  @tracked declare clients:DS.PromiseArray<Client>;

  get filteredClients() {
    let filterTerm = this.filterTerm;
    return this.clients.filter(function(client) {
      return !filterTerm || client.name.toLowerCase().indexOf(filterTerm.toLowerCase())>-1
        || client.short.toLowerCase().indexOf(filterTerm.toLowerCase())>-1;
    });
  }

  @action
  clearFilterTerm() {
    this.filterTerm = "";
  }

}
