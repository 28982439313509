import Model, { attr } from '@ember-data/model';

export default class Medium extends Model {
  @attr description;
  @attr mime;
  @attr name;
  @attr('number') height;
  @attr('number') width;
  @attr('number') size;
  @attr('number') gravity;
}
