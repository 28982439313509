import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default class User extends Model {
  @attr email;
  @attr login;
  @attr name;
  @attr password;
  @belongsTo('client') client;
  @hasMany('user-role') userRoles;

  cleanChanges() {
    var self = this;
    this.eachRelationship(function(name, descriptor) {
      if (descriptor.kind === 'hasMany') {
        let toremove = [];
        self.get(name).forEach(function(rel) {
          if (rel.get('isNew')) {
            rel.destroy();
            toremove.push(rel);
          }
        });
        for (var i=0; i<toremove.length; i++) {
          self.get(name).removeObject(toremove[i]);
        }
      }
      if (descriptor.kind === 'belongsTo') {
        var rel = self.get(name);
        if (rel.get('isNew')) {
          rel.destroy();
        }
      }
    });
    this.rollbackAttributes();
  }
}
