import JSONSerializer from './application';

export default JSONSerializer.extend({
  attrs: {
    occurrenceValues: {
      deserialize: 'records',
      serialize: 'rails',
    },
  },
});
