import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AbstractRoute extends Route {
  @service session;

  @service store;

  @service appState;

  @service permissions;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
    return super.beforeModel(transition);
  }
}
