import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import Property from "mikrobi-responsive/models/property";

class PropertyForm extends FormDataProxy<Property> {}

export default class AdminPropertyController extends Controller {

  @tracked declare formData: PropertyForm;

  prepareFormData(model: Property) {
    this.formData = new PropertyForm(model);
  }

  @action
  save() {
    let self = this;
    this.formData.save(false);
    return this.model.save().then((property: Property) => {
      return self.store.findRecord('property', property.id, { reload: true })
    })
      .then((property: Property) => {
        self.formData = new PropertyForm(property);
      });
  }

  @action
  delete() {
    let contr = this;
    this.model.destroyRecord().then(function(/* response */) {
      contr.transitionToRoute('admin.properties');
    });
  }
}
