/* global Chartist */
import $ from 'jquery';

import { observer } from '@ember/object';
import ChartistChart from './chartist-chart';

/**
 Mikrobi Chartist Chart component

 ```js
 {
   id: 1,
   name: 'Sebastian',
   friends: [3, 4],
   links: {
     house: '/houses/lefkada'
   }
 }
 ```

 `MikrobiChartistChart` will normalize the JSON payload to the JSON API format that the
 Ember Data store expects.

 @class MikrobiChartistChart
 @namespace mikrobi-responsive
 @extends ChartistChart
 */
export default ChartistChart.extend({

  attributeBindings: ['style'],

  init: function init() {
    this.doInit();
    this._super(...arguments);
  },

  doInit: function() {
    var chartData = this.getChartData();
    this.set('data', chartData.data);
    this.set('options', chartData.options);
  },

  setAnalysis: observer('analysis', function() {
    var chartData = this.getChartData();
    this.chart.update(chartData.data, chartData.options);
  }),

  didInsertElement: function didInsertElement() {
    this._super.apply(this, arguments);
    this.chart.on('draw', this.handleDrawEvent.bind(this));
    this.chart.on('created', this.handleCreatedEvent.bind(this));
  },

  handleCreatedEvent(data) {
    var component = this;
    var backgroundId = `#background_${component.get('definition.id')}`;
    $(backgroundId).each(function() {
      $('.ct-grids', data.svg._node).each(function(index, grid) {
        var box = grid.getBBox();
        if (box.height > 0) {
          var rect = new Chartist.Svg('rect');
          rect.attr({
            "x": box.x,
            "y": box.y,
            "width": box.width,
            "height": box.height,
            "style": `fill: url(${backgroundId})`
          });
          Chartist.Svg(grid).append(rect, true);
        }
      });
    });
  },

  handleDrawEvent(data) {
    if (data.type === 'bar') {
      data.element.attr({
        "style": `stroke: ${this.horizontal ? data.series.backgroundColor[data.index] : data.series.backgroundColor}`
      });
    }
  },

  getChartData() {
    var component = this;

    var chart = this.definition;

    var title = chart.get('chartParameters').map(function(cp) {
      return {
        "display": true,
        "text": cp.get('parameter.name')
      };
    });

    var labels = chart.get('chartParameters').map(function(/* cp */) {
      //return cp.get('parameter.name');
      return "";
    });

    var datasets = chart.get('chartParameters').map(function(cp) {
      var normalize = function(inval, paramId, prop) {
        return inval[paramId] ? parseFloat(inval[paramId].get(prop)) : null;
      };

      if (cp.get('seriesType')==='100%') {
        var sum = 0;
        cp.get('parameter.parameterValues').forEach(function(pv) {
          sum = sum + parseFloat(component.get('analysis.valuesByParameterValueId')[pv.get('id')].get('value'));
        });
        normalize = function(inval, paramId, prop) {
          return inval[paramId] ? (parseFloat(inval[paramId].get(prop)))/sum*100 : null;
        };
      }

      var dataset = cp.get('parameter.parameterValues').map(function (pv) {
        var color = pv.get('color');
        if (color.length === 4) {
          color = color + "00";
        }

        return {
          "label": [ pv.get('label') ],
          "data": [ normalize(component.get('analysis.valuesByParameterValueId'), pv.get('id'), 'value') ],
          "backgroundColor": [ `#${color}` ]
        };
      });

      return dataset;
    });

    var axes = chart.get('chartParameters').map(function(cp) {
      return {
        "stacked": cp.get('seriesType')==='stacked' || cp.get('seriesType')==='100%',
        "ticks": {
          "beginAtZero": false,
          "min": cp.get('seriesType')==='100%' ? 0 : chart.get('minValue'),
          "max": cp.get('seriesType')==='100%' ? 100 : chart.get('maxValue')
        },
        "barPercentage": 0.3
      };
    });

    var chartData = {
      data: {
        "labels": labels,
        "datasets": datasets[0],
        // chartist
        "series": datasets[0],
        "chartBackgroundId": "background_" + chart.get('id')
      },
      options: {
        "title": title[0],
        "legend": {
          display: false
        },
        responsive: true,
        scaleBeginAtZero: false,
        beginAtOrigin: false,
        "scales": {
          "xAxes": [ axes[0] ],
          "yAxes": [ axes[0] ]
        },
        // chartist
        horizontalBars: this.horizontal,
        stackBars: axes[0].stacked,
        height: this.height,
        high: axes[0].ticks.max,
        low: axes[0].ticks.min,
        axisX: {
          onlyInteger: true
        }
      }
    };

    return chartData;
  }
});
