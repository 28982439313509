import MikrobiAdapter from './application';

export default MikrobiAdapter.extend({

  /**
   Builds a URL for a given type and optional ID.
   By default, it pluralizes the type's name (for example, 'post'
   becomes 'posts' and 'person' becomes 'people'). To override the
   pluralization see [pathForType](#method_pathForType).
   If an ID is specified, it adds the ID to the path generated
   for the type, separated by a `/`.
   When called by RESTAdapter.findMany() the `id` and `snapshot` parameters
   will be arrays of ids and snapshots.
   @method buildURL
   @param {String} modelName
   @param {(String|Array|Object)} id single id or array of ids or query
   @param {(DS.Snapshot|Array)} snapshot single snapshot or array of snapshots
   @param {String} requestType
   @param {Object} query object of query parameters to send for query requests.
   @return {String} url
   */
  buildURL(modelName, id, snapshot, requestType, query) {
    if (query && query.prefix) {
      modelName = {
        modelName: modelName,
        prefix: query.prefix
      };
      delete query.prefix;
    }
    return this._super(modelName, id, snapshot, requestType, query);
  }
});
