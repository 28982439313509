import $ from 'jquery';
import { scheduleOnce } from '@ember/runloop';
import Component from '@ember/component';

export default Component.extend ({

  didRender: function() {
    this.matchHeight();
  },

  matchHeight: function() {
    scheduleOnce('afterRender', this, function() {
      let $tiles = $('.organism-tile');
      $tiles.addClass('is-matching-height');
      $tiles.matchHeight();
      $tiles.removeClass('is-matching-height');
    });
  }

});
