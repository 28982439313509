import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import Chart from "mikrobi-responsive/models/chart";
import ChartParameter from "mikrobi-responsive/models/chart-parameter";
import Parameter from "mikrobi-responsive/models/parameter";

class ChartForm extends FormDataProxy<Chart> {
  @tracked chartParameters: Array<ChartParameterForm>;

  constructor(model: Chart, parameters: Array<Parameter>) {
    super(model, undefined);

    this.chartParameters = model.chartParameters.filter(this.clearNewItems)
      .map(function(item) {
        return new ChartParameterForm(item, parameters);
      });

    this.registerChildren(this.chartParameters);
  }

  addChartParameter(chartParameter: ChartParameterForm): void {
    this.registerChild(chartParameter);
    this.chartParameters.addObject(chartParameter);
    chartParameter.setDirty();
  }
}

class ChartParameterForm extends FormDataProxy<ChartParameter> {

  parameters: Array<Parameter>;

  constructor(model: ChartParameter, parameters: Array<Parameter>) {
    super(model, undefined);
    this.parameters = parameters;
  }

  @action
  selectSeriesType(event: Event) {
    this.model.seriesType = event.target.value;
    this.setDirty();
  }

  @action
  selectParameter(event: Event) {
    let selectedId = event.target.value;
    let selectedParameter = this.parameters.find((param) => selectedId === param.get('id'));
    this.model.parameter = selectedParameter;
    this.model.send('becomeDirty');
    this.setDirty();
  }
}

export default class AdminChartController extends Controller {

  @tracked declare formData: ChartForm;

  declare parameters: Array<Parameter>;

  prepareFormData(model: Chart, parameters: Array<Parameter>) {
    this.formData = new ChartForm(model, parameters);
    this.parameters = parameters;
  }

  @action
  save() {
    const self = this;
    const wasNew = this.model.isNew;
    return this.formData.save(true).then((chart: Chart) => {
      self.formData.clear();
      if (wasNew) {
        self.transitionToRoute('admin.charts.chart', chart.id);
      } else {
        self.model.reload().then((chart: Chart) => {
          self.formData = new ChartForm(chart, self.parameters);
        })
      }
    });
  }

  @action
  createParameter() {
    const parameter: ChartParameter = this.store.createRecord('chart-parameter');
    parameter.chart = this.model;
    this.formData.addChartParameter(new ChartParameterForm(parameter, this.parameters));
  }

  @action
  deleteParameter(parameterForm: ChartParameterForm) {
    parameterForm.delete();
    parameterForm.setDirty();
  }

  @action
  delete() {
    let contr = this;
    this.model.destroyRecord().then(function(/* response */) {
      contr.transitionToRoute('admin.charts');
    });
  }

  get seriesTypes() {
    return [ "stacked", "100%" ];
  }
}
