import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import OccurrenceType from "mikrobi-responsive/models/occurrence-type";
import OccurrenceValue from "mikrobi-responsive/models/occurrence-value";
import Medium from "mikrobi-responsive/models/medium";
import $ from "jquery";
import { FormWithMediumLinks } from "mikrobi-responsive/types/forms/FormWithMediumLinks";
import Parameter from "mikrobi-responsive/models/parameter";

class OccurrenceTypeForm extends FormDataProxy<OccurrenceType> {
  @tracked occurrenceValues: Array<OccurrenceValueForm>;

  constructor(model: OccurrenceType) {
    super(model, undefined);

    this.occurrenceValues = model.occurrenceValues.filter(this.clearNewItems)
      .map(function(item) {
        return new OccurrenceValueForm(item);
      });

    this.registerChildren(this.occurrenceValues);
  }

  addOccurrenceValue(occurrenceValue: OccurrenceValueForm): void {
    this.registerChild(occurrenceValue);
    this.occurrenceValues.addObject(occurrenceValue);
  }
}

class OccurrenceValueForm extends FormWithMediumLinks<OccurrenceValue> {}

export default class AdminOccurrenceTypeController extends Controller {

  @tracked declare formData: OccurrenceTypeForm;
  @tracked declare currentMedium: Medium;
  @tracked declare mediumLinkDispatcher: OccurrenceValueForm;

  prepareFormData(model: OccurrenceType) {
    this.formData = new OccurrenceTypeForm(model);
  }

  @action
  save() {
    const self = this;
    const wasNew = this.model.isNew;
    return this.formData.save(true).then((occurrenceType: OccurrenceType) => {
      self.formData.clear();
      if (wasNew) {
        self.transitionToRoute('admin.occurrence-types.occurrence-type', occurrenceType.id);
      } else {
        self.model.reload().then((occurrenceType: OccurrenceType) => {
          self.formData = new OccurrenceTypeForm(occurrenceType);
        })
      }
    });
  }

  @action
  modal(medium: Medium, mediumLinkDispatcher: OccurrenceValueForm, selector: string, action: string, event: Event) {
    event.preventDefault();
    this.currentMedium = medium;
    this.mediumLinkDispatcher = mediumLinkDispatcher;
    $(selector).modal(action);
    event.stopPropagation();
  }

  @action
  createMedium(mediumLinkDispatcher: OccurrenceValueForm) {
    this.mediumLinkDispatcher = mediumLinkDispatcher;
    this.set('currentMedium', this.store.createRecord('medium'));
  }

  @action
  createValue() {
    const value: OccurrenceValue = this.store.createRecord('occurrence-value');
    value.occurrenceType = this.model;
    this.formData.addOccurrenceValue(new OccurrenceValueForm(value));
  }

  @action
  deleteValue(value: OccurrenceValueForm) {
    value.delete();
    value.setDirty();
  }
}
