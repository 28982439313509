import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import DateRangeService from "mikrobi-responsive/services/date-range";
import SamplingLocation from "mikrobi-responsive/models/sampling-location"
import Sample from "mikrobi-responsive/models/sample";

interface MainMenuData {
  location?: SamplingLocation;
  sample?: Sample;
}

export default class MainMenu extends Component<MainMenuData> {
  tagName = '';

  @service declare dateRange: DateRangeService;

  constructor(owner: unknown, args: MainMenuData) {
    super(owner, args);
  }

  get location(): SamplingLocation | undefined {
    return this.args.location;
  }

  get sample(): Sample | undefined {
    return this.args.sample;
  }

  @action
  setTimeFrom(timeFrom: Date): void {
    this.dateRange.timeFrom = timeFrom;
  }

  @action
  setTimeTo(timeTo: Date): void {
    this.dateRange.timeTo = timeTo;
  }
}
