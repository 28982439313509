import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class UserController extends Controller {
  @service appState;
  @service intl;
  @service store;

  @tracked client;

  @tracked userRoles = EmberObject.create({});

  @tracked _updatedName;
  @tracked _updatedLogin;
  @tracked _updatedPassword;

  get roles() {
    return this.appState.roles;
  }

  get userName() {
    if (this._updatedName === undefined) {
      return this.model.name;
    } else {
      return this._updatedName;
    }
  }

  set userName(name) {
    this._updatedName = name;
  }

  get userLogin() {
    if (this._updatedLogin === undefined) {
      return this.model.login;
    } else {
      return this._updatedLogin;
    }
  }

  set userLogin(login) {
    this._updatedLogin = login;
  }

  get userPassword() {
    if (this._updatedPassword === undefined) {
      return this.model.password;
    } else {
      return this._updatedPassword;
    }
  }

  set userPassword(password) {
    this._updatedPassword = password;
  }

  clearUpdates() {
    this._updatedName = this.model.name;
    this._updatedLogin = this.model.login;
    this._updatedPassword = '';
  }

  initUserRoles() {
    let self = this;
    this.roles.then(function (roles) {
      roles.forEach(function (role) {
        self.userRoles.set(
          role.get('name'),
          self.model.userRoles.any(function (userRole) {
            return userRole.get('role.id') === role.id;
          })
        );
      });
    });
  }

  @action
  toggleRole(role) {
    this._updatedUserRoles = this.userRoles;
    this._updatedUserRoles[role.name] = !this._updatedUserRoles[role.name];
  }

  @action
  save() {
    const self = this;
    let user = this.model;
    user.client = this.client;
    let selectedUserRoles = this.userRoles;
    let currentUserRoles = user.userRoles;

    user.set('name', this.userName);
    user.set('login', this.userLogin);
    if (this.userPassword) {
      user.set('password', this.userPassword);
    }

    this.roles.forEach(function (role) {
      if (selectedUserRoles[role.name]) {
        if (
          !currentUserRoles.any(function (currentRole) {
            return currentRole.role.get('name') === role.name;
          })
        ) {
          let newRole = self.store.createRecord('user-role');
          newRole.role = role;
          user.userRoles.addObject(newRole);
        }
      } else {
        let currentRole = currentUserRoles.find(function (currentRole) {
          return currentRole.role.get('name') === role.name;
        });
        if (currentRole) {
          currentRole.deleteRecord();
        }
      }
    });

    this.appState.dismissLoadingInfo();

    let callback = this.appState.addLoading(
      'info',
      this.intl.t('messages.savinguser'),
      true
    );

    user
      .save({
        adapterOptions: {
          clientId: this.client.get('id'),
        },
      })
      .then(
        function (/* response */) {
          callback();
          user.cleanChanges();
          self.userPassword = null;
          self.close();
        },
        function () {
          callback();
        }
      );
  }

  @action
  close() {
    this.transitionToRoute('admin.clients.client.users');
  }
}
