import AbstractRoute from '../../../abstract';
import Transition from "@ember/routing/-private/transition";
import PhylumIndexController from "mikrobi-responsive/controllers/admin/organisms/phylum";
import Phylum from "mikrobi-responsive/models/phylum";

export default class AdminOrganismsPhylumIndexRoute extends AbstractRoute {

  model(/* params: any, transition: Transition */): PromiseLike<any> | any {
    return this.modelFor('admin.organisms.phylum');
  }

  setupController(controller: PhylumIndexController, model: Phylum, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model);
  }

}
