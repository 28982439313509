import MikrobiAdapter from './application';

export default MikrobiAdapter.extend({

  /**
   Determines the pathname for a given type.

   By default, it pluralizes the type's name (for example,
   'post' becomes 'posts' and 'person' becomes 'people').

   ### Pathname customization

   For example if you have an object LineItem with an
   endpoint of "/line_items/".

   ```app/adapters/application.js
   import DS from 'ember-data';

   export default DS.RESTAdapter.extend({
      pathForType: function(modelName) {
        var decamelized = Ember.String.decamelize(modelName);
        return Ember.String.pluralize(decamelized);
      }
    });
   ```

   @method pathForType
   @param {String} modelName
   @return {String} path
   **/
  pathForType(modelName, snapshot) {
    if (snapshot === undefined) {
      return this._super(modelName);
    }
    var locale = this.get('appState.localePrefix');
    var clientId = this.get('session.data.authenticated.user.client.id');
    var siteId = snapshot.record.get('samplingLocation.site.id');
    var locationId = snapshot.record.get('samplingLocation.id');
    return `${locale}/clients/${clientId}/sites/${siteId}/sampling_locations/${locationId}/samples`;
  }
});
