import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import Medium from "mikrobi-responsive/models/medium";
import $ from "jquery";
import AttributeType from "mikrobi-responsive/models/attribute-type";
import AttributeValue from "mikrobi-responsive/models/attribute-value";
import { FormWithMediumLinks } from "mikrobi-responsive/types/forms/FormWithMediumLinks";
import Parameter from "mikrobi-responsive/models/parameter";

class AttributeTypeForm extends FormDataProxy<AttributeType> {
  @tracked attributeValues: Array<AttributeValueForm>;

  constructor(model: AttributeType) {
    super(model, undefined);

    this.attributeValues = model.attributeValues.filter(this.clearNewItems)
      .map(function(item) {
        return new AttributeValueForm(item);
      });

    this.registerChildren(this.attributeValues);
  }

  addAttributeValue(attributeValue: AttributeValueForm): void {
    this.registerChild(attributeValue);
    this.attributeValues.addObject(attributeValue);
  }
}

class AttributeValueForm extends FormWithMediumLinks<AttributeValue> {}

export default class AdminAttributeTypeController extends Controller {

  @tracked declare formData: AttributeTypeForm;
  @tracked declare currentMedium: Medium;
  @tracked declare mediumLinkDispatcher: AttributeValueForm;

  prepareFormData(model: AttributeType) {
    this.formData = new AttributeTypeForm(model);
  }

  @action
  save() {
    const self = this;
    const wasNew = this.model.isNew;
    return this.formData.save(true).then((attributeType: AttributeType) => {
      self.formData.clear();
      if (wasNew) {
        self.transitionToRoute('admin.attribute-types.attribute-type', attributeType.id);
      } else {
        self.model.reload().then((attributeType: AttributeType) => {
          self.formData = new AttributeTypeForm(attributeType);
        })
      }
    });
  }

  @action
  modal(medium: Medium, mediumLinkDispatcher: AttributeValueForm, selector: string, action: string, event: Event) {
    event.preventDefault();
    this.currentMedium = medium;
    this.mediumLinkDispatcher = mediumLinkDispatcher;
    $(selector).modal(action);
    event.stopPropagation();
  }

  @action
  createMedium(mediumLinkDispatcher: AttributeValueForm) {
    this.mediumLinkDispatcher = mediumLinkDispatcher;
    this.set('currentMedium', this.store.createRecord('medium'));
  }

  @action
  createValue() {
    const value: AttributeValue = this.store.createRecord('attribute-value');
    value.attributeType = this.model;
    this.formData.addAttributeValue(new AttributeValueForm(value));
  }

  @action
  deleteValue(value: AttributeValueForm) {
    value.delete();
    value.setDirty();
  }
}
