import { attr, belongsTo } from '@ember-data/model';
import OccurrenceType from "mikrobi-responsive/models/occurrence-type";
import MediaContainerModel from "mikrobi-responsive/models/media-container";

export default class OccurrenceValue extends MediaContainerModel {
  @attr() declare description: string;
  @attr('number') declare value: number;
  @attr() declare label: string;
  @belongsTo('occurrence-type') declare occurrenceType: OccurrenceType;
}
