import { inject as service } from '@ember/service';
import $ from 'jquery';
import { observer } from '@ember/object';
import MikrobiChartistChart from './mikrobi-chartist-chart';
import config from '../config/environment';

/**
 Mikrobi Location Chart component

 `MikrobiChartistChart` will normalize the JSON payload to the JSON API format that the
 Ember Data store expects.

 @class MikrobiLocationChart
 @namespace mikrobi-responsive
 @extends MikrobiChartistChart
 */
export default MikrobiChartistChart.extend({

  intl: service(),

  session: service(),

  dateRange: service(),

  appState: service(),

  didReceiveAttrs: function() {
  },

  doInit: function() {
    // required to disable execution of superclass function
    this.set('data', {});
  },

  chartDataObserver: observer('location', 'chart', 'dateRange.timeFrom', 'dateRange.timeTo', function() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    var contr = this;
    var locale = this.get('appState.localePrefix');
    var clientId = this.get('session.data.authenticated.user.client.id');
    var samplingLocation = this.location;
    var siteId = samplingLocation.get('site.id');
    var locationId = samplingLocation.get('id');
    var timeFrom = this.get('dateRange.timeFrom');
    var timeTo = this.get('dateRange.timeTo');
    var chartId = this.get('definition.id');
    var qparams = {
      per_page: 20,
      page: 1,
      order: 'time DESC'
    };
    if (timeFrom) {
      var strTimeFrom = JSON.stringify(new Date(parseInt(timeFrom.getTime())));
      qparams['_qand[sample][time][0]'] = `[ge]${strTimeFrom}`;
    }
    if (timeTo) {
      var strTimeTo = JSON.stringify(new Date(parseInt(timeTo.getTime())));
      qparams['_qand[sample][time][1]'] = `[le]${strTimeTo}`;
    }
    var chartData = $.get(`${config.apiEndpoint}/${locale}/clients/${clientId}/sites/${siteId}/sampling_locations/${locationId}/chart_data/${chartId}.json`, qparams);
    return chartData.then(function(response) {
      contr.set('options', response.options);
      contr.set('chartData', response.data);
      return response['chart-data'];
    });
  }),

  transpose(array) {
    if (!array || array.length<1) {
      return array;
    }

    var arrayLength = array[0].length;
    var newArray = [];
    for(var i = 0; i < arrayLength; i++) {
      newArray.push([]);
    }

    for(var j = 0; j < array.length; j++) {
      for(var k = 0; k < arrayLength; k++) {
        newArray[k].push(array[j][k]);
      }
    }

    return newArray;
  },

  setChartData: observer('chartData', function() {
    var chartData = this.getChartData();
    this.chart.update(chartData.data, chartData.options);
  }),

  getChartData() {
    var component = this;

    var chart = this.definition;

    var title = chart.get('chartParameters').map(function(cp) {
      return {
        "display": true,
        "text": cp.get('parameter.name')
      };
    });

    var labels = this.chartData.labels.reverse().map(function(time) {
      return component.get('intl').formatDate(time, { 'format': 'shortWithYear' });
    });
    var series = this.chartData.series.map(function(serie, index) {
      serie = serie.reverse();
      var cp = chart.get('chartParameters').objectAt(index);
      if (cp.get('seriesType')==='100%') {
        var s100 = serie.map(function(s) {
          var sum = 0;
          s.forEach(function(pv) {
            sum = sum + parseFloat(pv);
          });
          var normalize = function(inval) {
            return inval/sum*100;
          };
          return s.map(function(v) {
            return normalize(v);
          });
        });
        return s100;
      } else {
        return serie;
      }
    });

    series = this.horizontal ? series : series.map(this.transpose);

    chart.get('chartParameters').forEach(function(cp, index) {
      if (component.get('horizontal')) {
        series[index].label = cp.get('parameter.parameterValues').map(function (pv) {
          return pv.get('label');
        });

        series[index].backgroundColor = cp.get('parameter.parameterValues').map(function (pv) {
          var color = pv.get('color');
          if (color.length === 4) {
            color = "00" + color;
          }
          return `#${color}`;
        });
      }
      else {
        cp.get('parameter.parameterValues').forEach(function (pv, pindex) {
          var color = pv.get('color');
          if (color.length === 4) {
            color = "00" + color;
          }
          if (series[index][pindex]) {
            series[index][pindex].backgroundColor = `#${color}`;
            series[index][pindex].label = pv.get('label');
          }
        });
      }
    });

    var axes = chart.get('chartParameters').map(function(cp) {
      return {
        "stacked": cp.get('seriesType')==='stacked' || cp.get('seriesType')==='100%',
        "ticks": {
          "beginAtZero": false,
          "min": cp.get('seriesType')==='100%' ? 0 : chart.get('minValue'),
          "max": cp.get('seriesType')==='100%' ? 100 : chart.get('maxValue')
        },
        "barPercentage": 0.3
      };
    });

    var chartData = {
      data: {
        "labels": labels,
        // chartist
        "series": series[0],
        "chartBackgroundId": "background_" + chart.get('id')
      },
      options: {
        "title": title[0],
        "legend": {
          display: false
        },
        responsive: true,
        scaleBeginAtZero: false,
        beginAtOrigin: false,
        "scales": {
          "xAxes": axes,
          "yAxes": axes
        },
        // chartist
        horizontalBars: this.horizontal,
        stackBars: axes[0].stacked,
        height: this.height,
        high: axes[0].ticks.max,
        low: axes[0].ticks.min,
        axisX: {
          onlyInteger: true
        }
      }
    };

    return chartData;
  }
});
