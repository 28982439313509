import EmberObject, { computed } from '@ember/object';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default Model.extend({
  createdAt: attr('date'),
  description: attr(),
  editor: attr(),
  samplingLocation: belongsTo('sampling-location'),
  organismOccurrences: hasMany('organism-occurrence'),
  sampleAttributes: hasMany('sample-attribute'),
  mediumLinks: hasMany('medium-links'),
  time: attr('date', { defaultValue: function() { return new Date(); } }),
  updatedAt: attr('date'),
  analysis: attr('analysis'),

  sampleAttributeByAttributeType: computed('sampleAttributes', function() {
    var sampleAttributes = this.sampleAttributes;
    var sampleAttributeByAttributeType = EmberObject.create({});
    sampleAttributes.forEach(function(sampleAttribute) {
      sampleAttributeByAttributeType.set(sampleAttribute.get("attributeValue.attributeType.id"), sampleAttribute);
    });
    return sampleAttributeByAttributeType;
  }),

  organismOccurrenceByOrganism: computed('organismOccurrences', function() {
    var organismOccurrences = this.organismOccurrences;
    var organismOccurrenceByOrganism = EmberObject.create({});
    organismOccurrences.forEach(function(occurrence) {
      var microOrganismId = occurrence.get('microOrganism.id');
      if (microOrganismId) {
        organismOccurrenceByOrganism.set(microOrganismId, occurrence);
      }
    });
    return organismOccurrenceByOrganism;
  }),

  cleanChanges: function() {
    var self = this;
    this.eachRelationship(function(name, descriptor) {
      if (descriptor.kind === 'hasMany') {
        let toremove = [];
        self.get(name).forEach(function(rel) {
          if (rel.get('isNew')) {
            rel.destroy();
            toremove.push(rel);
          }
        });
        for (var i=0; i<toremove.length; i++) {
          self.get(name).removeObject(toremove[i]);
        }
      }
      if (descriptor.kind === 'belongsTo') {
        var rel = self.get(name);
        if (rel.get('isNew')) {
          rel.destroy();
        }
      }
    });
    this.rollbackAttributes();
  },

  sampleChanged: computed('dirtyType', 'sampleAttributes.@each.dirtyType',
    'organismOccurrences.@each.dirtyType', 'mediumLinks.@each.dirtyType', function() {
      var self = this;

      var dirty = (this.dirtyType !== undefined);

      this.eachRelationship(function(name, descriptor) {
        if (!dirty) {
          if (descriptor.kind === 'hasMany') {
            dirty = self.get(name).any(function(rel) {
              return rel.get('dirtyType') !== undefined;
            });
          }
          if (descriptor.kind === 'belongsTo') {
            var rel = self.get(name);
            dirty = (rel.get('dirtyType') !== undefined);
          }
        }
      });

      return dirty;
  })

});
