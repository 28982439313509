import JSONSerializer from './application';

export default JSONSerializer.extend({
  attrs: {
    medium: {
      deserialize: 'records'
    }
  },

  /**
   Override default behavior for the medium-belongsTo-relationship
   @method keyForRelationship
   @param {String} key
   @param {String} relationshipKind
   @param {String} method
   @return {String} normalized key
   */
  keyForRelationship: function keyForRelationship(key, relationshipKind, method) {
    if (key === 'medium' && method === 'deserialize') {
      return key;
    } else {
      return this._super(key, relationshipKind, method);
    }
  }
});
