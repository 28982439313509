import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {

  dynamicRoute: service(),

  actions: {
    didTransition() {
      this.set('dynamicRoute.analysis.sample', 'analysis.sample.result');
    }
  },

  model() {
    return this.modelFor('analysis.sample').get('analysis');
  },

  setupController(controller, model) {
    // Call _super for default behavior
    this._super(controller, model);

    var baseData = this.modelFor('analysis');

    controller.set('baseData', baseData);
  }

});
