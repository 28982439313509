import AbstractRoute from '../abstract';
import { inject as service } from "@ember/service";
import PermissionService from "mikrobi-responsive/services/permissions";

export default class SuperAdminOnlyRoute extends AbstractRoute {

  @service declare permissions: PermissionService;

  beforeModel(transition: any): any {
    let before = super.beforeModel(transition);
    if (this.permissions.hasRole(["superadministrator"])) {
      return before;
    } else {

    }
  }
}
