import AbstractAdminRoute from '../../../../abstract';
import SiteController from "mikrobi-responsive/controllers/admin/clients/client/sites/site";
import Transition from "@ember/routing/-private/transition";
import Site from "mikrobi-responsive/models/site";
import Client from "mikrobi-responsive/models/client";

export default class SiteRoute extends AbstractAdminRoute {
  model(params: { site_id: null }, /* transition: Transition */): PromiseLike<Site> {
    if (params.site_id === 'new') {
      const site = this.store.createRecord('site');
      const client = this.modelFor('admin.clients.client');
      site.client = client;
      return site;
    }
    return this.store.peekRecord('site', params.site_id);
  }

  setupController(controller: SiteController, model: Site, transition: Transition) {
    super.setupController(controller, model, transition);
    const client: Client = this.modelFor('admin.clients.client');
    controller.prepareFormData(model, client);
  }
}
