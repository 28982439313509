import AbstractRoute from '../../../abstract';
import MicroOrganism from "mikrobi-responsive/models/micro-organism";
import Transition from "@ember/routing/-private/transition";
import OrganismController from "mikrobi-responsive/controllers/admin/organisms/phylum/organism";

export default class AdminOrganismsPhylumOrganismRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.organism_id === 'new') {
      let org: MicroOrganism = this.store.createRecord('micro-organism');
      org.phylum = this.modelFor('admin.organisms.phylum');
      return org;
    }
    return this.store.findRecord('micro-organism', params.organism_id, { reload: true });
  }

  setupController(controller: OrganismController, model: MicroOrganism, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model);
  }

}
