import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AnalysisAllRoute extends Route {

  @service session;

  beforeModel() {
    this.session.requireAuthentication('index');
    this.transitionTo('analysis', '_');
  }
}
