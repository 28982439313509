import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DateRangeService extends Service {
  @tracked timeTo: Date | null = null;
  @tracked timeFrom: Date | null = null;

  constructor() {
    super(...arguments);
    //this.set('samples.timeFrom', moment().subtract(1, 'months').hours(0).minutes(0).seconds(0).utc().valueOf());
  }

  get samplesSet(): boolean {
    return this.timeTo !== null || this.timeFrom !== null;
  }
}

declare module '@ember/service' {
  interface Registry {
    'dateRange': DateRangeService;
  }
}
