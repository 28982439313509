import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';


export default Component.extend({

  dynamicRoute: service(),

  dynamicPath: computed('dynamicRoute.analysis.sample', function() {
    return this.get('dynamicRoute.analysis.sample') || 'analysis.sample.result';
  })

});
