import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import BaseModalComponent from './modal-base';
import config from '../config/environment';

export default BaseModalComponent.extend ({

  session: service(),

  appState: service(),

  selectionArray: null,

  init() {
    this._super(...arguments);
    this.selectionArray = [];
  },

  actions: {
    select(chart, event) {
      var selection = this.selectionArray;
      var chartId = chart.id;
      if (event.target.checked) {
        selection.push(chart.id);
      } else {
        var idx = selection.indexOf(chartId);
        if (idx > -1) {
          selection.splice(idx, 1);
        }
      }
      this.set('selectionArray', [].concat(selection));
    }
  },

  reportlink: computed('sample', 'selectionArray', function() {
    var host = config.apiEndpoint;
    var locale = this.get('appState.localePrefix');
    var sampleId = this.get('sample.id');
    var clientId = this.get('session.data.authenticated.user.client.id');
    var siteId = this.get('sample.samplingLocation.site.id');
    var locationId = this.get('sample.samplingLocation.id');
    var chartIds = this.selectionArray.join(',');
    var baseUrl = `/${locale}/clients/${clientId}/sites/${siteId}/sampling_locations/${locationId}/report.pdf`;
    var queryString = `_qand[sample][sampling_location_id]=${locationId}&page=1&order=time DESC&per_page=15&chart_ids=${chartIds}&sample_id=${sampleId}`;
    return `${host}${baseUrl}?${queryString}`;
  }),

  selection: computed('selectionArray', function() {
    return this.selectionArray.reduce(function(sel, item, index) {
      sel["chart_" + item] = index+1;
      return sel;
    }, {});
  })

});
