import { inject as service } from '@ember/service';
import jQuery from 'jquery';
import BaseModalComponent from './modal-base';
import RSVP from 'rsvp';

let Logger = console;

export default BaseModalComponent.extend ({

  store: service(),

  actions: {

    triggerFile(selector) {
      jQuery(selector).click();
      return false;
    },

    changeFile(medium, event) {
      var comp = this;

      var input = event.target;
      var file = input.files[0];
      this.file = file;


      if (file) {
        comp.withMedium(medium, function (medium) {
          medium.set('mime', file.type);
        });

        var reader = new FileReader();
        reader.addEventListener("load", function () {
          comp.withMedium(medium, function (medium) {
            medium.set('base64data', reader.result);
          });
        }, false);
        reader.readAsDataURL(file);
      }
    },

    rollback(medium) {
      delete this.file;
      this.withMedium(medium, function (medium) {
        medium.rollbackAttributes();
      });
      this.close();
    },

    save(medium) {
      let comp = this;
      let store = this.store;
      let target = this.target;
      this.withMedium(medium, function (medium) {
        var created = medium.get('isNew');
        medium.save().then(function (medium) {
          if (created) {
            var link = store.createRecord('medium-link');
            link.set('medium', medium);
            target.mediumLinks.addObject(link);
          }
          if (comp.file) {
            comp.upload(medium.get('id'), comp.file).then(function (response) {
              delete comp.file;
              medium.set('base64data', undefined);
              Logger.log(response);
              comp.close();
            });
          } else {
            comp.close();
          }
        });
      });
    }
  },

  close() {
    jQuery(this.element).modal('hide');
  },

  upload(mediumId, file) {
    if (file) {
      var data = new FormData();
      data.append('upload', file);
      data.append('direct', true);
      return new RSVP.Promise(function(resolve, reject) {
        jQuery.ajax({
          url: `/en_US/media/${mediumId}/upload`,
          type: 'POST',
          data: data,
          cache: false,
          dataType: 'text',
          processData: false, // Don't process the files
          contentType: false, // Set content type to false as jQuery will tell the server its a query string request
          success: function(data/* , textStatus, jqXHR */)
          {
            resolve(data);
          },
          error: function(jqXHR, textStatus, errorThrown)
          {
            Logger.error(textStatus);
            reject(errorThrown);
          }
        });
      });
    }

    return new RSVP.Promise(function(resolve) {
      resolve(null);
    });
  },

  withMedium(medium, callback) {
    new RSVP.Promise(function(resolve) {
      resolve(medium);
    }).then(callback);
  }

});
