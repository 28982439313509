import AbstractRoute from '../../../abstract';
import Transition from "@ember/routing/-private/transition";
import Client from "mikrobi-responsive/models/client";
import ClientDataController from "mikrobi-responsive/controllers/admin/clients/client/data";

export default class AdminClientsClientDataRoute extends AbstractRoute {

  setupController(controller: ClientDataController, model: Client, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model);
  }
}
