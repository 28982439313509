import { computed } from '@ember/object';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default Model.extend({
  createdAt: attr('date'),
  updatedAt: attr('date'),
  sample: belongsTo('sample'),
  analysisParameters: hasMany('analysis-parameters'),

  valuesByParameterValueId: computed('analysisParameters', function() {
    return this.analysisParameters.reduce(function(valuemap, param) {
      valuemap[param.get('parameterValue.id')] = param;
      return valuemap;
    }, {});
  }),

  valuesByParameterIdAndIndex: computed('analysisParameters', function() {
    return this.analysisParameters.reduce(function(valuemap, param) {
      var pid = param.get('parameterValue.parameter.id');
      valuemap[pid] = valuemap[pid] || {};
      valuemap[pid][param.get('parameterValue.index')] = param.get('value');
      return valuemap;
    }, {});
  })

});
