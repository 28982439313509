import { attr, belongsTo } from '@ember-data/model';
import AttributeType from "mikrobi-responsive/models/attribute-type";
import MediaContainerModel from "mikrobi-responsive/models/media-container";

export default class AttributeValue extends MediaContainerModel {
  @attr('number') declare index: number;
  @attr('number') declare value: number;
  @attr('number') declare row: number;
  @attr() declare description: string;
  @attr() declare label: string;
  @belongsTo('attribute-type') declare attributeType: AttributeType;
}
