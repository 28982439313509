import JSONSerializer from './application';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default JSONSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    userRoles: {
      deserialize: 'records',
      serialize: 'rails'
    }
  }
});
