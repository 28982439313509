import AbstractRoute from '../../abstract';
import Transition from "@ember/routing/-private/transition";
import AdminTextFragmentController from "mikrobi-responsive/controllers/admin/text-fragments/text-fragment";
import TextFragment from "mikrobi-responsive/models/text-fragment";

export default class AdminTextFragmentRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.text_fragment_id === 'new') {
      return this.store.createRecord('text-fragment');
    }
    return this.store.findRecord('text-fragment', params.text_fragment_id, { reload: true });
  }

  setupController(controller: AdminTextFragmentController, model: TextFragment, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model);
  }

}
