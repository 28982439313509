import { inject as service } from '@ember/service';
import { isEnabled } from '@ember/canary-features';
import { underscore } from '@ember/string';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import config from '../config/environment';

export default JSONAPIAdapter.extend({
  session: service(),

  appState: service(),

  intl: service(),

  host: config.apiEndpoint,

  /**
   Creates a path for the model like `de-DE${prefix}/${clientPrefix}${path}`
   where
   path: is the value returned by the _super() method
   prefix: is the value of modelName.prefix if modelName is an object and has this property - in this case modelName
    is set to modelName.modelName
   clientPrefix: is clients/${clientId} from the session for non-base data
   @method pathForType
   @param {String} modelName
   @return {String} path
   **/
  pathForType(modelName, snapshot) {
    let prefix = '';
    if (typeof modelName === 'object' && modelName.prefix) {
      prefix = modelName.prefix;
      modelName = modelName.modelName;
    }
    const adapterOptions = snapshot ? snapshot.adapterOptions || {} : {};
    const locale = this.get('appState.localePrefix');
    const clientId =
      adapterOptions.clientId ||
      this.get('session.data.authenticated.user.client.id');
    const path = underscore(this._super(modelName));
    const clientPrefix =
      config.configTypes.indexOf(modelName) < 0 ? `clients/${clientId}/` : '';
    return `${locale}${prefix}/${clientPrefix}${path}`;
  },

  /**
   Builds a URL for a given type and optional ID.

   By default, it pluralizes the type's name (for example, 'post'
   becomes 'posts' and 'person' becomes 'people'). To override the
   pluralization see [pathForType](#method_pathForType).

   If an ID is specified, it adds the ID to the path generated
   for the type, separated by a `/`.

   When called by RESTAdapter.findMany() the `id` and `snapshot` parameters
   will be arrays of ids and snapshots.

   @method buildURL
   @param {String} modelName
   @param {(String|Array|Object)} id single id or array of ids or query
   @param {(DS.Snapshot|Array)} snapshot single snapshot or array of snapshots
   @param {String} requestType
   @param {Object} query object of query parameters to send for query requests.
   @return {String} url
   */
  buildURL(modelName, id, snapshot, requestType, query) {
    return (
      this.myBuildURL(modelName, id, snapshot, requestType, query) + '.json'
    );
  },

  myBuildURL(modelName, id, snapshot, requestType, query) {
    switch (requestType) {
      case 'findRecord':
        return this.urlForFindRecord(id, modelName, snapshot);
      case 'findAll':
        return this.urlForFindAll(modelName, snapshot);
      case 'query':
        return this.urlForQuery(query, modelName);
      case 'queryRecord':
        return this.urlForQueryRecord(query, modelName);
      case 'findMany':
        return this.urlForFindMany(id, modelName, snapshot);
      case 'findHasMany':
        return this.urlForFindHasMany(id, modelName, snapshot);
      case 'findBelongsTo':
        return this.urlForFindBelongsTo(id, modelName, snapshot);
      case 'createRecord':
        return this.urlForCreateRecord(modelName, snapshot);
      case 'updateRecord':
        return this.urlForUpdateRecord(id, modelName, snapshot);
      case 'deleteRecord':
        return this.urlForDeleteRecord(id, modelName, snapshot);
      default:
        return this._buildURL(modelName, id, snapshot);
    }
  },

  /**
   * @method urlForCreateRecord
   * @param {String} modelName
   * @param {DS.Snapshot} snapshot
   * @return {String} url
   */
  urlForCreateRecord(modelName, snapshot) {
    return this._buildURL(modelName, undefined, snapshot);
  },

  /**
   * @method urlForUpdateRecord
   * @param {String} id
   * @param {String} modelName
   * @param {DS.Snapshot} snapshot
   * @return {String} url
   */
  urlForUpdateRecord(id, modelName, snapshot) {
    return this._buildURL(modelName, id, snapshot);
  },

  /**
     Builds a URL for a `store.findAll(type)` call.
      Example:
      ```app/adapters/comment.js
     import DS from 'ember-data';
      export default DS.JSONAPIAdapter.extend({
       urlForFindAll(modelName, snapshot) {
         return 'data/comments.json';
       }
     });
     ```
      @method urlForFindAll
     @param {String} modelName
     @param {DS.SnapshotRecordArray} snapshot
     @return {String} url
     */
  urlForFindAll: function urlForFindAll(modelName, snapshot) {
    return this._buildURL(modelName, undefined, snapshot);
  },

  /**
   @method _buildURL
   @private
   @param {String} modelName
   @param {String} id
   @return {String} url
   */
  _buildURL(modelName, id, snapshot) {
    let url = [];
    const host = this.host;
    const prefix = this.urlPrefix();
    let path;

    if (modelName) {
      path = this.pathForType(modelName, snapshot);
      if (path) {
        url.push(path);
      }
    }

    if (id) {
      url.push(encodeURIComponent(id));
    }
    if (prefix) {
      url.unshift(prefix);
    }

    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }

    return url;
  },

  /**
   Called by the store when a newly created record is
   saved via the `save` method on a model record instance.

   The `createRecord` method serializes the record and makes an Ajax (HTTP POST) request
   to a URL computed by `buildURL`.

   See `serialize` for information on how to customize the serialized form
   of a record.

   @method createRecord
   @param {DS.Store} store
   @param {DS.Model} type
   @param {DS.Snapshot} snapshot
   @return {Promise} promise
   */
  createRecord: function createRecord(store, type, snapshot) {
    const payload = {};
    if (isEnabled('ds-improved-ajax') && !this._hasCustomizedAjax()) {
      const request = this._requestFor({
        store,
        type,
        snapshot,
        requestType: 'createRecord',
      });

      payload[type.modelName] = request.data;
      request.data = payload;

      return this._makeRequest(request);
    } else {
      let data = {};
      const serializer = store.serializerFor(type.modelName);
      const url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      payload[underscore(type.modelName)] = data;
      data = payload;

      return this.ajax(url, 'POST', {
        data: data,
        contentType: 'application/json; charset=utf-8',
      });
    }
  },

  /**
   @method updateRecord
   @param {DS.Store} store
   @param {DS.Model} type
   @param {DS.Snapshot} snapshot
   @return {Promise} promise
   */
  updateRecord: function updateRecord(store, type, snapshot) {
    let data = {};
    const serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot);
    const payload = {};
    payload[underscore(type.modelName)] = data;
    data = payload;

    const id = snapshot.id;
    const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    return this.ajax(url, 'PUT', {
      data: data,
      contentType: 'application/json; charset=utf-8',
    });
  },

  /**
   @method ajaxOptions
   @private
   @param {String} url
   @param {String} type The request type GET, POST, PUT, DELETE etc.
   @param {Object} options
   @return {Object}
   */
  ajaxOptions: function ajaxOptions(/*url, type , options */) {
    const hash = this._super.apply(this, arguments);

    if (hash.contentType) {
      hash.contentType = 'application/json; charset=utf-8';
    }

    return hash;
  },

  dataForRequest(/* params */) {
    const data = this._super.apply(this, arguments);
    return 'object' === typeof data && Object.keys(data).length === 0
      ? undefined
      : data;
  },

  /**
   * Convert a request object into a hash which can be passed to `jQuery.ajax`.
   *
   * @private
   * @method _requestToJQueryAjaxHash
   * @param {Object} request
   * @return {Object} jQuery ajax hash
   */
  _requestToJQueryAjaxHash(request) {
    const hash = {};

    hash.type = request.method;
    hash.url = request.url;
    hash.dataType = 'json';
    hash.context = this;

    if (request.data) {
      if (request.method !== 'GET') {
        hash.contentType = 'application/json; charset=utf-8';
        //hash.data = JSON.stringify(request.data);
        hash.data = request.data;

        if (request.method === 'POST') {
          hash.data = JSON.stringify(request.data);
        }
      } else {
        hash.data = request.data;
      }
    }

    var headers = request.headers;
    if (headers !== undefined) {
      hash.beforeSend = function (xhr) {
        Object.keys(headers).forEach(function (key) {
          return xhr.setRequestHeader(key, headers[key]);
        });
      };
    }

    return hash;
  },

  isSuccess(status, headers, payload) {
    this.checkAuthentication(status, headers, payload);
    if ((payload.errors || payload.key) && payload.status > 399) {
      if (payload.status !== 401) {
        this.appState.addError(payload);
      }
      return false;
    } else {
      return this._super.apply(this, arguments);
    }
  },

  isInvalid(status, headers, payload) {
    this.checkAuthentication(status, headers, payload);
    if (payload.key && payload.status > 399) {
      return true;
    } else {
      return this._super.apply(this, arguments);
    }
  },

  checkAuthentication(status /*, headers, payload*/) {
    if (status === 401) {
      this.appState.invalidateSession();
    }
  },
});
