import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Transition from "@ember/routing/-private/transition";
import IntlService from "ember-intl/addon/services/intl";

export default class ApplicationRoute extends Route {

  @service declare session: any;

  @service declare intl: IntlService;

  beforeModel(transition: Transition): any {
    this.intl.setLocale('de');
    return super.beforeModel(transition);
  }

  @action
  invalidateSession() {
    this.session.invalidate();
    this.store.unloadAll();
  }
}
