import MediumLink from "mikrobi-responsive/models/medium-link";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import { tracked } from "@glimmer/tracking";
import MediaContainerModel from "mikrobi-responsive/models/media-container";

class MediumLinksDispatcher {
  setter: Function;
  mediumLinks: MediumLinksDispatcher;

  constructor(setter: Function) {
    this.setter = setter;
    this.mediumLinks = this;
  }

  addObject(link: MediumLink): void {
    this.setter(link);
  }
}

class MediumLinkForm extends FormDataProxy<MediumLink> {}

export class FormWithMediumLinks<Type extends MediaContainerModel> extends FormDataProxy<Type> {

  @tracked mediumLinks: Array<MediumLinkForm>;

  declare mediumLinkDispatcher: MediumLinksDispatcher;

  constructor(model: Type) {
    super(model, undefined);

    this.mediumLinkDispatcher = new MediumLinksDispatcher(this.addObject.bind(this));

    this.mediumLinks = model.mediumLinks.filter(this.clearNewItems)
      .map(function(item) {
        return new MediumLinkForm(item, model.mediumLinks);
      });

    this.registerChildren(this.mediumLinks);
  }

  addToLinks(mediumLink: MediumLink): void {
    this.model.mediumLinks.pushObject(mediumLink);
    this.model.send('becomeDirty');
    let linkForm = new MediumLinkForm(mediumLink, this.model.mediumLinks);
    linkForm.setDirty();
    this.registerChild(linkForm);
    this.mediumLinks.addObject(linkForm);
  }

  addObject(link: MediumLink): void {
    this.addToLinks(link);
  }
}
