import AbstractRoute from '../../abstract';
import Transition from "@ember/routing/-private/transition";
import Property from "mikrobi-responsive/models/property";
import AdminPropertyController from "mikrobi-responsive/controllers/admin/properties/property";

export default class AdminPropertyRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.property_id === 'new') {
      return this.store.createRecord('property');
    }
    return this.store.findRecord('property', params.property_id, { reload: true });
  }

  setupController(controller: AdminPropertyController, model: Property, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model);
  }

}
