import AbstractRoute from '../../abstract';
import Transition from "@ember/routing/-private/transition";
import Chart from "mikrobi-responsive/models/chart";
import AdminChartController from "mikrobi-responsive/controllers/admin/charts/chart";

export default class AdminChartRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.chart_id === 'new') {
      return this.store.createRecord('chart');
    }
    return this.store.findRecord('chart', params.chart_id, { reload: true });
  }

  setupController(controller: AdminChartController, model: Chart, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model, this.store.findAll('parameter'));
  }

}
