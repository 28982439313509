import AbstractRoute from '../../abstract';

export default class AdminOrganismsPhylumRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.phylum_id === 'new') {
      return this.store.createRecord('phylum');
    }
    return this.store.findRecord('phylum', params.phylum_id, { reload: true, include: 'micro_organisms' });
  }

}
