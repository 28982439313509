export default {
  time: {
    hhmmss: {
      hour:   'numeric',
      minute: 'numeric',
      second: 'numeric'
    },
    hhmm: {
      hour:   'numeric',
      minute: 'numeric'
    }
  },
  date: {
    hhmmss: {
      hour:   'numeric',
      minute: 'numeric',
      second: 'numeric'
    },
    hhmm: {
      hour:   'numeric',
      minute: 'numeric'
    },
    short: {
      day: '2-digit',
      month: '2-digit'
    },
    shortWithYear: {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    },
    dateTimeShort: {
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  },
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    Int: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    Lab: {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    },
    Lab2: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    param: {
      "4": {
        "0": {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        },
        "1": {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        },
        "2": {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        "3": {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        "4": {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        "5": {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        },
        "6": {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  }
};
