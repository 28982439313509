import EmberRouter from '@ember/routing/router';
import config from 'mikrobi-responsive/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('logout');
  this.route('analysis-all', { path: '/analysis' });
  this.route('analysis', { path: '/analysis/:location_id' }, function () {
    this.route('sample', { path: '/sample/:sample_id' }, function () {
      this.route('data');
      this.route('organisms', function () {
        this.route('media', { path: '/:organism_id/media' });
      });
      this.route('result');
    });
    this.route('report', { path: '/report/:sample_id' }, function () {
      this.route('charts');
      this.route('organisms');
    });
  });

  this.route('admin', function () {
    this.route('clients', function () {
      this.route('client', { path: '/:client_id' }, function () {
        this.route('data');
        this.route('users', function () {
          this.route('user', { path: '/:user_id' });
        });
        this.route('sites', function () {
          this.route('site', { path: '/:site_id' });
        });
      });
    });
    this.route('organisms', function () {
      this.route('phylum', { path: '/:phylum_id' }, function() {
        this.route('organism', { path: '/:organism_id' });
      });
    });
    this.route('parameters', function () {
      this.route('parameter', { path: '/:parameter_id' });
    });
    this.route('occurrence-types', function () {
      this.route('occurrence-type', { path: '/:occurrence_type_id' });
    });
    this.route('attribute-types', function () {
      this.route('attribute-type', { path: '/:attribute_type_id' });
    });
    this.route('properties', function () {
      this.route('property', { path: '/:property_id' });
    });
    this.route('text-fragments', function () {
      this.route('text-fragment', { path: '/:text_fragment_id' });
    });
    this.route('charts', function () {
      this.route('chart', { path: '/:chart_id' });
    });
  });
});
