import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend ({
  classNameBindings: ['isHovered:hovered'],

  isHovered: computed('hoveredValue', function() {
    return !!this.hoveredValue && this.get('hoveredValue.mediumLinks.length')>0;
  }),

  actions: {
    onValueEnter(value) {
      this.set('hoveredValue', value);
    },
    onValueLeave() {
      this.set('hoveredValue', undefined);
    }
  }
});
