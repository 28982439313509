import Client from './client';
import SamplingLocation from './sampling-location';
import DS from 'ember-data';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class Site extends Model {
  @attr() declare name: string;
  @belongsTo('client') declare client: DS.PromiseObject<typeof Client>;
  @hasMany('sampling-location') declare samplingLocations: DS.PromiseArray<SamplingLocation>;
  @attr() declare address1: string;
  @attr() declare address2: string;
  @attr() declare address3: string;
  @attr() declare address4: string;
  @attr() declare createdAt: Date;
}
