import JSONSerializer from './application';

export default JSONSerializer.extend({
  attrs: {
    chart: {
      deserialize: 'ids',
      serialize: 'ids',
    },
    parameter: {
      deserialize: 'ids',
      serialize: 'ids',
    },
  },
});
