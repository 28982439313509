import AbstractRoute from '../../../abstract';

export default class AdminClientsClientUsersRoute extends AbstractRoute {

  model(/* params: {}, transition: Transition */): PromiseLike<any> | any {
    console.log("admin.clients.client.users: model()");
    const client = this.modelFor('admin.clients.client');
    return client.users;
  }
}
