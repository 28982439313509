import Model from "@ember-data/model";
import EmberObject from "@ember/object";
import { tracked } from "@glimmer/tracking";
import MutableEnumerable from "@ember/array/-private/mutable-enumerable";
import Enumerable from "@ember/array/-private/enumerable";
import { UnwrapComputedPropertyGetter } from "@ember/object/-private/types";

export class FormDataProxy<Type extends Model> extends EmberObject {

  @tracked private declare _dirty: boolean;
  @tracked private declare _deleted: boolean;
  @tracked protected declare _props: Map<keyof Type, Type[keyof Type]>;
  @tracked private declare _children: MutableEnumerable<FormDataProxy<any>>;
  @tracked private declare readonly _container: MutableEnumerable<Type> | undefined;
  @tracked protected declare readonly _model: Type;

  constructor(model: Type, container: MutableEnumerable<Type> | undefined = undefined) {
    super();

    this._dirty = false;
    this._deleted = false;
    this._model = model;
    this._props = new Map<keyof Type, Type[keyof Type]>();
    this._children = [];
    this._container = container;
  }

  setDirty() {
    this._dirty = true;
  }

  registerChildren(children: Enumerable<FormDataProxy<any>>) {
    this._children.addObjects(children);
  }

  registerChild(child: FormDataProxy<any>) {
    this._children.addObject(child);
  }

  unknownProperty<K extends keyof Type>(key: K): Type[keyof Type] | UnwrapComputedPropertyGetter<Type[K]> | undefined {
    return this._props.has(key) ? this._props.get(key) : this._model.get(key);
  }

  setUnknownProperty<K extends keyof Type>(key: K, value: Type[K]): Type[K] {
    this._props.set(key, value);
    this._dirty = true;
    return value;
  }

  get hasPendingChanges(): boolean {
    return this._dirty || this._children.any((proxy) => proxy.hasPendingChanges);
  }

  delete() {
    this._deleted = true;
  }

  save(root: boolean = true): Promise<Type> | null {
    let record = this._model;
    let result;

    if (this._deleted) {
      result = record.deleteRecord();
    } else {
      this._props.forEach((value, key) => {
        record.set(key, value);
      });

      this._children.forEach((child: FormDataProxy<any>) => {
        child.save(false);
      });

      if (this._container !== undefined && !this._container.includes(record)) {
        this._container.addObject(record);
      }
    }

    if (root) {
      result = record.save();
    } else {
      result = null;
    }

    return result;
  }

  get model(): Type {
    return this._model;
  }

  clearNewItems(item: any) {
    if (item && item.get && !item.get('id')) {
      if (item.isNew) {
        item.rollbackAttributes();
      }
      return false;
    }
    return true;
  }

  clear(): Promise<Type> | null {
    this._children.forEach((child: FormDataProxy<any>) => {
      child.clear();
    });

    this._model.rollbackAttributes();
  }

}
