import Controller from '@ember/controller';
import { observer, computed } from '@ember/object';

export default Controller.extend({
  attributeBindings: ['id', 'role'],

  page: 1,
  perPage: 12,
  order: null,
  phylum: null,
  common: false,
  inSample: false,
  searchTextInput: null,

  meta: null,

  resetPage: observer('phylum', 'model', 'common', 'inSample', 'searchTextInput', function() {
    this.set('page', 1);
  }),

  filteredOrganisms: computed('page', 'perPage', 'order', 'phylum', 'common', 'model', 'searchTextInput', function() {
    var page = this.page;
    var perPage = this.perPage;
    var order = this.order;
    var sample = this.model;
    var phylumId = this.get('phylum.id');
    var common = this.common;
    var searchText = this.searchTextInput;

    var queryParams = { 'page': page, 'per_page': perPage};
    if (order) {
      queryParams['order']= order;
    }
    if (searchText) {
      queryParams['_qand[micro_organism][name]'] = `[like]%${searchText}%`;
    }
    if (common) {
      queryParams['_qand[micro_organism][common]'] = 1;
    } else {
      if (phylumId) {
        queryParams['_qand[micro_organism][phylum][show]'] = 1;
        queryParams.prefix = `/phylums/${phylumId}`;
      } else {
        if (sample && !searchText) {
          queryParams['micro_organism_ids'] = sample.get('organismOccurrences').map(
            function (occurrence) {
              return occurrence.get('microOrganism.id');
            }).join();
        }
      }
    }

    let organisms = this.store.query('micro_organism', queryParams);

    organisms.then((result) => {
      this.set('meta', result.get('meta'));
    });

    return organisms;
  }),

  sampleSet: observer('model', function() {
    this.set('phylum', null);
    this.set('common', false);
  }),

  phylumSet: observer('phylum', function() {
    this.set('page', 1);
    this.set('common', false);
  }),

  searchText: computed('searchText', {
    get() {
      var searchText = this.searchTextInput;
      return searchText;
    },
    set(key, value) {
      if (value && value.length>2) {
        this.set('page', 1);
        this.set('searchTextInput', value);
        return value;
      } else {
        if (this.searchTextInput) {
          this.set('searchTextInput', null);
          this.set('page', 1);
        }
      }
      return value;
    }
  })
});
