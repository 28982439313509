import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {

  appState: service(),

  intl: service(),

  model(params) {
    if (params.sample_id === '-1') {
      let sample = this.store.createRecord('sample');
      sample.set('samplingLocation', this.modelFor('analysis').get('location'));
      return sample;
    } else {
      var callback = this.appState.addLoading("info", this.intl.t('messages.loadingsample'), true);

      var sample = this.store.findRecord('sample', params.sample_id, { reload: true });
      sample.then(callback, callback);
      return sample;
    }
  }
});
