import { computed } from '@ember/object';
import Controller from '@ember/controller';

export default Controller.extend({
  parametersById: computed('baseData.parameters', function() {
    return this.get('baseData.parameters').reduce(function(parammap, p) {
      parammap[p.id] = p;
      return parammap;
    }, {});
  }),

  saprobie: computed('model', function() {
    return this.getChartData("1");
  }),

  chartData: computed('model', function() {
    var controller = this;
    return this.store.peekAll('chart').map(function(chart) {
      return controller.getChartData(chart.get('id'));
    });
  }),

  getChartData(chartId) {
    var controller = this;

    var chart = this.store.peekRecord('chart', chartId);

    var title = chart.get('chartParameters').map(function(cp) {
      return {
        "display": true,
        "text": cp.get('parameter.name')
      };
    });

    var labels = chart.get('chartParameters').map(function(/* cp */) {
      //return cp.get('parameter.name');
      return "";
    });

    var datasets = chart.get('chartParameters').map(function(cp) {
      var normalize = function(inval) {
        return inval;
      };

      if (cp.get('seriesType')==='100%') {
        var sum = 0;
        cp.get('parameter.parameterValues').forEach(function(pv) {
          sum = sum + parseFloat(controller.get('model.valuesByParameterValueId')[pv.get('id')].get('value'));
        });
        normalize = function(inval) {
          return inval/sum*100;
        };
      }

      var dataset = cp.get('parameter.parameterValues').map(function (pv) {
        var color = pv.get('color');
        if (color.length === 4) {
          color = color + "00";
        }

        return {
          "label": [ pv.get('label') ],
          "data": [ normalize(parseFloat(controller.get('model.valuesByParameterValueId')[pv.get('id')].get('value'))) ],
          "backgroundColor": [ `#${color}` ]
        };
      });

      return dataset;
    });

    var axes = chart.get('chartParameters').map(function(cp) {
      return {
        "stacked": cp.get('seriesType')==='stacked' || cp.get('seriesType')==='100%',
        "ticks": {
          "beginAtZero": false,
          "min": chart.get('minValue'),
          "max": chart.get('maxValue')
        },
        "barPercentage": 0.3
      };
    });

    var chartData = {
      data: {
        "labels": labels,
        "datasets": datasets[0],
        // chartist
        "series": datasets[0]
      },
      options: {
        "title": title[0],
        "legend": {
          display: false
        },
        responsive: true,
        scaleBeginAtZero: false,
        beginAtOrigin: false,
        "scales": {
          "xAxes": [ axes[0] ],
          "yAxes": [ axes[0] ]
        },
        // chartist
        horizontalBars: true,
        stackBars: axes[0].stacked,
        axisX: {
          onlyInteger: true,
          low: axes[0].ticks.min,
          high: axes[0].ticks.max
        },
        height: 120
      }
    };

    return chartData;
  }
});
