import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import MutableArray from "@ember/array/mutable";

export class LoadingInfoEntry {
  @tracked finished: boolean = false;
  type: String;
  message: String;
  spinner: boolean;

  constructor(type: String, message: String, spinner: boolean) {
    this.type = type;
    this.message = message;
    this.spinner = spinner;
  }

  dismiss() {}
}

export default class AppStateService extends Service {
  @service intl: any;

  @service store: any;

  @service session: any;

  @tracked loadingInfos: MutableArray<LoadingInfoEntry>;

  @tracked currentLocation: any;

  _roles: any;

  locales: any = {
    de: 'de-DE',
    en: 'en-US',
  };

  constructor() {
    super(...arguments);
    this.loadingInfos = [];

  }

  get roles() {
    if (this._roles === undefined) {
      this._roles = this.store.findAll('role');
    }
    return this._roles;
  }

  get localePrefix() {
    return this.locales[this.intl.locale];
  }

  get isLoading() {
    return this.loadingInfos.length > 0;
  }

  addError(error: any) {
    const intl = this.intl;
    if (error.errors && error.errors.length>0) {
      let message: String = error.errors.reduce((m: String, e: any) => {
        return m + " " + intl.t('errors.' + e[1]);
      }, "");
      this.addMessage('danger', message);
    } else if (error.key) {
      this.addMessage('danger', intl.t('errors.' + error.key));
    }
  }

  addMessage(type: String, message: String) {
    this.addLoading(type, message, false);
  }

  addLoading(type: String, message: String, spinner: boolean) {
    const appState = this;
    const info = new LoadingInfoEntry(type, message, spinner);
    info.dismiss = function () {
      appState.dismissLoadingInfo(info);
    };
    this.loadingInfos.addObject(info);

    return function (/* response */) {
      appState.dismissLoadingInfo(info);
    };
  }

  dismissLoadingInfo(info: LoadingInfoEntry | undefined) {
    if (info===undefined) {
      this.loadingInfos.forEach((info) => {
        info.finished = true;
      });
      this.loadingInfos.clear();
    } else {
      info.finished = true;
      this.loadingInfos.removeObject(info);
    }
  }

  get currentLocationId() {
    return this.currentLocation ? this.currentLocation.get('id') : null;
  }

  invalidateSession() {
    this.session.invalidate();
    this.store.unloadAll();
  }
}

declare module '@ember/service' {
  interface Registry {
    'appState': AppStateService;
  }
}
