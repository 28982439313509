import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import Client from "mikrobi-responsive/models/client";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import { action } from "@ember/object";

class ClientForm extends FormDataProxy<Client> {}

export default class ClientDataController extends Controller {

  @tracked declare formData: ClientForm;

  prepareFormData(model: Client) {
    this.formData = new ClientForm(model);
  }

  @action
  save() {
    this.formData.save(false);
    const self = this;
    return this.model.save().then((client: Client) => {
      self.replaceRoute("admin.clients.client", client.get('id'));
    });
  }
}
