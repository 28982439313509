import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import MicroOrganism from "mikrobi-responsive/models/micro-organism";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import { action } from "@ember/object";
import $ from "jquery";
import OccurrenceType from "mikrobi-responsive/models/occurrence-type";
import MediumLink from "mikrobi-responsive/models/medium-link";
import Medium from "mikrobi-responsive/models/medium";

class OrganismForm extends FormDataProxy<MicroOrganism> {
  @tracked mediumLinks: Array<MediumLinkForm>;

  constructor(model: MicroOrganism) {
    super(model, undefined);

    this.mediumLinks = model.mediumLinks
      .filter(function(item) {
        return !!item.get('id');
      }).map(function(item) {
        return new MediumLinkForm(item, model.mediumLinks);
      });

    this.registerChildren(this.mediumLinks);
  }

  addToLinks(mediumLink: MediumLink): void {
    let linkForm = new MediumLinkForm(mediumLink, this.model.mediumLinks);
    linkForm.setDirty();
    this.registerChild(linkForm);
    this.mediumLinks.addObject(linkForm);
  }
}

class MediumLinkForm extends FormDataProxy<MediumLink> {}

class MediumLinksDispatcher {
  formData: OrganismForm;

  constructor(formData: OrganismForm) {
    this.formData = formData;
  }

  addObject(link: MediumLink): void {
    this.formData.addToLinks(link);
  }
}

export default class OrganismController extends Controller {

  @tracked declare formData: OrganismForm;

  @tracked declare currentMedium: Medium;

  prepareFormData(model: MicroOrganism) {
    this.formData = new OrganismForm(model);
  }

  get occurrenceTypes() {
    return this.store.findAll('occurrence-type');
  }

  @action
  save() {
    this.formData.save(false);
    const self = this;
    return this.model.save().then((organism: MicroOrganism) => {
      //self.replaceRoute("admin.organisms.phylum.organism", organism.get('id'));
      self.formData = new OrganismForm(organism);
    });
  }

  @action
  modal(medium: Medium, selector: string, action: string, event: Event) {
    event.preventDefault();
    this.currentMedium = medium;
    $(selector).modal(action);
    event.stopPropagation();
  }

  @action
  createMedium() {
    this.set('currentMedium', this.store.createRecord('medium'));
  }

  @action
  updateOccurrenceType(ocType: OccurrenceType) {
    this.formData.set('occurrenceType', ocType);
  }

  get mediumLinkDispatcher() {
    return {
      mediumLinks: new MediumLinksDispatcher(this.formData)
    }
  }
}
