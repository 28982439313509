import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from "@glimmer/tracking";
import MutableArray from "@ember/array/mutable";
import DS from "ember-data";

export default class SelectList extends Component {
  @tracked value: any;
  @tracked elements: MutableArray<any>;

  constructor(owner: unknown, args: { value: any, elements: DS.PromiseArray<any>}) {
    super(owner, args);

    this.value = args.value;
    this.elements = args.elements;
  }

  @action
  select(event: Event) {
    let selectedId = event.target.value;
    let selectedType = this.elements.find((ocType) => selectedId === ocType.get('id'));
    this.value = selectedType;
    if (this.args.onChange) {
      this.args.onChange(selectedType);
    }
  }
}
