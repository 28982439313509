import Model, { attr, belongsTo } from '@ember-data/model';
import Parameter from "mikrobi-responsive/models/parameter";

export default class ParameterValue extends Model {
  @attr() declare varname: string;
  @attr() declare formula: string;
  @attr() declare color: string;
  @attr('number') declare index: number;
  @attr('number') declare value: number;
  @attr('number') declare row: number;
  @attr() declare description: string;
  @attr() declare label: string;
  @belongsTo('parameter') declare parameter: Parameter;
}
