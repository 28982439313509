import AbstractRoute from '../../abstract';
import Transition from "@ember/routing/-private/transition";
import AdminAttributeTypeController from "mikrobi-responsive/controllers/admin/attribute-types/attribute-type";
import AttributeType from "mikrobi-responsive/models/attribute-type";

export default class AdminAttributeTypeRoute extends AbstractRoute {

  model(params: any, /* transition: Transition */): PromiseLike<any> | any {
    if (params.attribute_type_id === 'new') {
      return this.store.createRecord('attribute-type');
    }
    return this.store.findRecord('attribute-type', params.attribute_type_id, { reload: true });
  }

  setupController(controller: AdminAttributeTypeController, model: AttributeType, transition: Transition) {
    super.setupController(controller, model, transition);
    controller.prepareFormData(model);
  }

}
