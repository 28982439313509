import Service, { inject as service } from '@ember/service';
import EmberObject from '@ember/object';

export default Service.extend({

  store: service(),

  init() {
    this._super(...arguments);
    this.set('analysis', EmberObject.create());
  }

});
