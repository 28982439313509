import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {
  session: service('session'),
  model(params) {
    return this.get('session.data.authenticated.user.client.sites').get(params.site_id);
  }
});
