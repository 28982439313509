import Model, { attr, hasMany } from "@ember-data/model";
import {computed} from "@ember/object";
import DS from "ember-data";
import ChartParameter from "mikrobi-responsive/models/chart-parameter";

export default class Chart extends Model {
  @attr('number') declare minValue: number;
  @attr('number') declare maxValue: number;
  @attr() declare chartType: string;
  @attr() declare title: string;
  @attr() declare background: string;
  @hasMany('chart-parameter') declare chartParameters: DS.PromiseArray<ChartParameter>;

  @computed('background')
  get backgroundMarkers() {
    var pattern = /;?\(([0-9abcdef]{6}):([0-9]*\.?[0-9]+)\)/;
    var markers = [];
    var background = this.background;
    while (background) {
      var match = pattern.exec(background);
      if (match) {
        markers.push({
          "color": "#" + match[1],
          "offset": match[2]
        });
        background = background.substring(background.indexOf(match[0]) + match[0].length);
      } else {
        background = null;
      }
    }
    return markers;
  };

  calculateColorForValue(value: any) {
    var cval = (value-this.minValue) / (this.maxValue-this.minValue);
    var lowerColor, lowerBound, upperColor, upperBound;
    var markers = this.backgroundMarkers;
    if (markers.length===0) {
      return;
    }
    var idx=0;
    while(idx<markers.length && cval>markers[idx].offset) {
      idx = idx + 1;
    }
    if (idx===0) {
      return markers[idx].color;
    } else if(idx===markers.length) {
      return markers[idx-1].color;
    } else {
      lowerColor = markers[idx-1].color.substring(1);
      lowerBound = markers[idx-1].offset;
      upperColor = markers[idx].color.substring(1);
      upperBound = markers[idx].offset;

      var ratio = (cval-lowerBound) / (upperBound-lowerBound);
      var color2 = lowerColor;
      var color1 = upperColor;

      var hex = function(x) {
        x = x.toString(16);
        return (x.length === 1) ? '0' + x : x;
      };

      var r = Math.ceil(parseInt(color1.substring(0,2), 16) * ratio + parseInt(color2.substring(0,2), 16) * (1-ratio));
      var g = Math.ceil(parseInt(color1.substring(2,4), 16) * ratio + parseInt(color2.substring(2,4), 16) * (1-ratio));
      var b = Math.ceil(parseInt(color1.substring(4,6), 16) * ratio + parseInt(color2.substring(4,6), 16) * (1-ratio));

      return "#" + hex(r) + hex(g) + hex(b);
    }
  };
}
