import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({

  linearGradient: computed('chart', function() {
    var chart = this.chart;
    var markers = chart.get('backgroundMarkers');
    if (markers.length > 0) {
      return [{
        "id": "background_" + chart.get('id'),
        "stops": markers
      }];
    } else {
      return undefined;
    }
  })

});
