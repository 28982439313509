import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({

  store: service(),

  color: computed('sample.analysis', 'colorChartId', 'previewValue', function() {
    /*
    var chart = this.get('store').peekRecord('chart', this.get('colorChartId'));
    var value = this.get('previewValue');
    if (value) {
      return chart.calculateColorForValue(parseFloat(value));
    }
    */
  }),

  formattedPreviewValue: computed('previewValue', function() {
    var value = this.previewValue;
    return parseFloat(value).toFixed(1);
  })

});
