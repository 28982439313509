import Model, { attr, belongsTo } from '@ember-data/model';
import Parameter from "mikrobi-responsive/models/parameter";
import Chart from "mikrobi-responsive/models/chart";

export default class ChartParameter extends Model {
  @attr() declare description: string;
  @attr() declare seriesType: string;
  @belongsTo('parameter') declare parameter: Parameter;
  @belongsTo('chart') declare chart: Chart;
}
