import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { observer, computed } from '@ember/object';

export default Controller.extend({

  flashMessages: service(),

  session: service(),

  intl: service(),

  appState: service(),

  dynamicRoute: service(),

  actions: {
    save() {
      var contr = this;
      var sample = this.model;
      var sampleIsNew = sample.get('isNew');
      var callback = this.appState.addLoading(
        'info',
        this.intl.t('messages.savingsample'),
        true
      );
      try {
        sample.save().then(function (sample) {
          callback();
          sample.cleanChanges();
          contr.transitionToRoute(contr.get('dynamicRoute.analysis.sample'), sample.get('samplingLocation.id'), sample.get('id'));
          if (sampleIsNew) {
            contr.set('appState.createdSample', sample);
          }
        }, function(err) {
          callback();
          contr.flashMessages.danger("Couldn't save: "  + err);
          contr.get('model').reload();
        });
      } catch(e) {
        callback();
        sample.reload().then(function(sample) {
          sample.cleanChanges();
        });
      }
    },

    reload() {
      var self = this;
      this.model.rollbackAttributes();
      var dropNewRelations = function(related) {
        if (related.get('isNew')) {
          related.destroy();
        }
      };
      this.get('model.sampleAttributes').forEach(dropNewRelations);
      this.get('model.organismOccurrences').forEach(dropNewRelations);
      this.model.reload().then(function(model) {
        self.set('model', model);
      });
    },

    delete() {
      var contr = this;
      var sample = this.model;
      var samplingLocationId = sample.get('samplingLocation.id');
      sample.destroyRecord().then(function(/* response */) {
        contr.set('appState.currentSample', undefined);
        contr.transitionToRoute('analysis', samplingLocationId);
      });
    }
  },

  sampleChanged: computed('model.sampleChanged', function() {
      return this.get('model.sampleChanged');
  }),

  sampleClean: computed('sampleChanged', function() {
    return !this.sampleChanged || this.get('model.isSaving');
  }),

  unlessSampleClean: computed('sampleClean', function() {
    return !this.sampleClean;
  }),

  sampleCleanOrNoLocation: computed('sampleClean', 'model.samplingLocation.id', function() {
    return this.sampleClean || !this.get('model.samplingLocation.id');
  }),

  unlessAnalysisExists: computed('model.analysis', function() {
    return this.get('model.analysis') ? undefined : 'disabled';
  }),

  updateDate: observer('set_date', function(contr, key) {
    var ndate = contr.get(key);
    var sdate = new Date(this.get('model.time'));
    sdate.setDate(ndate.getDate());
    sdate.setMonth(ndate.getMonth());
    sdate.setYear(ndate.getFullYear());
    this.set('model.time', sdate);
  }),

  set_time: computed('model.time', {
    get(/* key */) {
      const time = this.get('model.time');
      return this.intl.formatTime(time, { format: 'hhmm'});
    },
    set(key, value) {
      let m = value.match(/(\d{1,2}):(\d{2}).*/);
      if (!m) {
        m = value.match(/(\d{1,2}):(\d*)/);
      }
      if (!m) {
        m = value.match(/(\d{2})\D*(\d{1,2}).*/);
      }
      if (m) {
        const time = this.get('model.time');
        const sdate = new Date(time);
        sdate.setHours(parseInt(m[1]));
        sdate.setMinutes(parseInt(m[2]));
        if (!isNaN(sdate.getTime())) {
          this.set('model.time', sdate);
        }
        return value;
      } else {
        m = value.match(/(\d{1,2}).*/);
        if (m) {
          return m[1];
        } else {
          return '';
        }
      }
    }
  }),

  modelObserver: observer('model', function(target, key) {
    this.set('appState.currentSample', target.get(key));
  })

});
