import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {

  session: service('session'),

  dynamicRoute: service(),

  actions: {
    didTransition() {
      this.set('dynamicRoute.analysis.sample', 'analysis.report');
    }
  },

  model(params) {
    return this.store.findRecord('sample', params.sample_id, { reload: true });
  },

  setupController(controller, model) {
    // Call _super for default behavior
    this._super(controller, model);

    var baseData = this.modelFor('analysis');

    controller.set('baseData', baseData);
  }
});
