import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import { action } from "@ember/object";
import Phylum from "mikrobi-responsive/models/phylum";

class PhylumForm extends FormDataProxy<Phylum> {}

export default class PhylumIndexController extends Controller {

  @tracked declare formData: PhylumForm;

  prepareFormData(model: Phylum) {
    this.formData = new PhylumForm(model);
  }

  @action
  save() {
    this.formData.save(false);
    const self = this;
    return this.model.save().then((phylum: Phylum) => {
      //self.replaceRoute("admin.organisms.phylum", phylum.get('id'));
      self.formData = new PhylumForm(phylum);
    });
  }
}
