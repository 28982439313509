import JSONSerializer from './application';

export default JSONSerializer.extend({
  attrs: {
    _changed: {
      serialize: false
    },
    sample: {
      serialize: false
    },
    microOrganism: {
      serialize: 'id'
    },
    occurrenceValue: {
      serialize: 'id'
    }
  }
});
