import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import BaseModalComponent from './modal-base';
import config from '../config/environment';

export default BaseModalComponent.extend({
  session: service(),

  appState: service(),

  dateRange: service(),

  selectionArray: null,

  init() {
    this._super(...arguments);
    this.setMaxSamples(20);
    set(this, 'selectionArray', []);
  },

  @action
  select(chart, event) {
    var selection = this.selectionArray;
    var chartId = chart.id;
    if (event.target.checked) {
      selection.push(chart.id);
    } else {
      var idx = selection.indexOf(chartId);
      if (idx > -1) {
        selection.splice(idx, 1);
      }
    }
    this.set('selectionArray', [].concat(selection));
  },

  @action
  setMaxSamples(maxSamples) {
    this.set('maxSamples', maxSamples);
  },

  @action
  updateMaxSamples(event) {
    let selectedValue = parseInt(event.target.value, 10);
    this.setMaxSamples(selectedValue);
  },

  reportlink: computed('sample', 'selectionArray', 'dateRange.timeFrom', 'dateRange.timeTo', 'maxSamples', function() {
    var host = config.apiEndpoint;
    var locale = this.get('appState.localePrefix');
    var sampleId = this.get('sample.id');
    var clientId = this.get('session.data.authenticated.user.client.id');
    var siteId = this.get('sample.samplingLocation.site.id');
    var locationId = this.get('sample.samplingLocation.id');
    var chartIds = this.selectionArray.join(',');
    var baseUrl = `/${locale}/clients/${clientId}/sites/${siteId}/sampling_locations/${locationId}/report.pdf`;

    var qparams = "";
    var timeFrom = this.get('dateRange.timeFrom');
    var timeTo = this.get('dateRange.timeTo');
    if (timeFrom) {
      var strTimeFrom = JSON.stringify(new Date(parseInt(timeFrom.getTime())));
      qparams = `${qparams}&_qand[sample][time][0]=[ge]${strTimeFrom}`;
    }
    if (timeTo) {
      var strTimeTo = JSON.stringify(new Date(parseInt(timeTo.getTime())));
      qparams = `${qparams}&_qand[sample][time][1]=[le]${strTimeTo}`;
    }

    var maxSamples = this.get('maxSamples');

    var queryString = `_qand[sample][sampling_location_id]=${locationId}&page=1&order=time DESC&per_page=${maxSamples}&chart_ids=${chartIds}&sample_id=${sampleId}${qparams}`;
    return `${host}${baseUrl}?${queryString}`;
  }),

  selection: computed('selectionArray', function() {
    return this.selectionArray.reduce(function(sel, item, index) {
      sel["chart_" + item] = index+1;
      return sel;
    }, {});
  })

});
