import Component from '@glimmer/component';
import { action } from '@ember/object';
import { LoadingInfoEntry } from "mikrobi-responsive/services/app-state";

interface LoadingInfoData {
  entry: LoadingInfoEntry;
  timeout: bigint;
}

export default class LoadingInfoEntryComponent extends Component<LoadingInfoData> {

  constructor(owner: unknown, args: LoadingInfoData) {
    super(owner, args);
  }

  @action
  close() {
    this.entry.dismiss();
  }

  get entry() {
    return this.args.entry;
  }

  get alertClass() {
    return 'alert-' + this.entry.type;
  }

  get dismissible() {
    return this.entry.type === 'danger' || this.entry.type === 'warning';
  }

}
