import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import { observer } from '@ember/object';
import config from '../config/environment';

export default Component.extend({

  session: service(),

  dateRange: service(),

  appState: service(),

  didReceiveAttrs: function() {
    this.loadTableData();
  },

  tableDataObserver: observer('location', 'dateRange.timeFrom', 'dateRange.timeTo', function() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    this.loadTableData();
  }),

  loadTableData() {
    var comp = this;
    var locale = this.get('appState.localePrefix');
    var clientId = this.get('session.data.authenticated.user.client.id');
    var samplingLocation = this.location;
    var siteId = samplingLocation.get('site.id');
    var locationId = samplingLocation.get('id');
    var timeFrom = this.get('dateRange.timeFrom');
    var timeTo = this.get('dateRange.timeTo');
    var qparams = {
      per_page: 20,
      page: 1,
      order: 'time DESC'
    };
    if (timeFrom) {
      var strTimeFrom = JSON.stringify(new Date(parseInt(timeFrom.getTime())));
      qparams['_qand[sample][time][0]'] = `[ge]${strTimeFrom}`;
    }
    if (timeTo) {
      var strTimeTo = JSON.stringify(new Date(parseInt(timeTo.getTime())));
      qparams['_qand[sample][time][1]'] = `[le]${strTimeTo}`;
    }
    $.get(`${config.apiEndpoint}/${locale}/clients/${clientId}/sites/${siteId}/sampling_locations/${locationId}/table_data.json`, qparams)
    .then(function(response) {
      comp.set('tableData', response.table_data);
    });
  }

});
