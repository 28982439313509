import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

export default Route.extend(AuthenticatedRouteMixin, {

  dynamicRoute: service(),

  model() {
    return this.modelFor('analysis.sample');
  },

  redirect(model, transition) {
    this.transitionTo(this.dynamicPath(model, transition.params['analysis.sample.dynamic']), model);
  },

  dynamicPath(model /*, params */) {
    var myRoute = 'analysis.sample';
    var dynamicRoute = this.dynamicRoute.get(myRoute);
    if (dynamicRoute==='result' || !dynamicRoute) {
      if (model && model.get('analysis')) {
        dynamicRoute = dynamicRoute || 'result';
      } else {
        dynamicRoute = 'data';
      }
    }
    return myRoute + '.' + dynamicRoute;
  }
});
