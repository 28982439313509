import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

export default class AnalysisController extends Controller {
  @service session;

  @service dateRange;

  @service appState;

  @service intl;

  queryParams = ['page', 'perPage', 'order', 'timeFrom', 'timeTo'];

  page = 1;
  perPage = 10;
  order = 'time DESC';
  timeFrom = null;
  timeTo = null;

  meta = null;

  @action sampleSelected(sample, path) {
    this.transitionToRoute(path, sample.id);
  }

  query() {
    const page = this.page;
    const perPage = this.perPage;
    const order = this.order;
    const timeFrom = this.dateRange.timeFrom;
    const timeTo = this.dateRange.timeTo;
    const location = this.model.location;
    const locationId = location
      ? location.get('id')
      : this.appState.currentLocationId;

    const queryParams = { page: page, per_page: perPage, order: order };
    if (timeFrom) {
      const strTimeFrom = JSON.stringify(timeFrom);
      queryParams['_qand[sample][time][0]'] = `[ge]${strTimeFrom}`;
    }
    if (timeTo) {
      const strTimeTo = JSON.stringify(timeTo);
      queryParams['_qand[sample][time][1]'] = `[le]${strTimeTo}`;
    }
    if (locationId) {
      queryParams['_qand[sample][sampling_location_id]'] = `${locationId}`;
    }
    return queryParams;
  }

  @computed(
    'appState.createdSample',
    'dateRange.{timeFrom,timeTo}',
    'model.location',
    'order',
    'page',
    'perPage',
    'store'
  )
  get filteredSamples() {
    const callback = this.appState.addLoading(
      'info',
      this.intl.t('messages.loadingsamples'),
      true
    );
    let samples = this.store.query('sample', this.query());
    samples.then((result) => {
      this.meta = result.meta;
    });
    samples.then(callback, callback);
    return samples;
  }

  @computed('store')
  get sites() {
    return this.store
      .peekAll('site')
      .sortBy('name')
      .filter(function (site) {
        return site.get('samplingLocations.length') > 0;
      });
  }
}
