import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend ({

  attributeBindings: ['checked'],

  intl: service(),

  checked: computed('intl.locale', 'value', function() {
    return this.get('intl.locale')===this.value;
  }),

  actions: {
    select(locale) {
      this.set('intl.locale', locale);
    }
  }

});
