import { inject as service } from '@ember/service';
import Transform from '@ember-data/serializer/transform';

export default Transform.extend({
  store: service(),
  deserialize: function(payload) {
    var analysis = this.store.normalize('analysis', payload);
    this.store.push(analysis);
    return this.store.peekRecord('analysis', payload.id);
  }
});
