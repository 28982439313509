import { typeOf } from '@ember/utils';
import { underscore } from '@ember/string';
import { inject as service } from '@ember/service';
import { warn } from '@ember/debug';
import JSONSerializer from '@ember-data/serializer/json';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default JSONSerializer.extend(EmbeddedRecordsMixin, {
  session: service(),

  /**
   The `normalizeResponse` method is used to normalize a payload from the
   server to a JSON-API Document.
   http://jsonapi.org/format/#document-structure
   This method delegates to a more specific normalize method based on
   the `requestType`.
   To override this method with a custom one, make sure to call
   `return this._super(store, primaryModelClass, payload, id, requestType)` with your
   pre-processed data.
   Here's an example of using `normalizeResponse` manually:
   ```javascript
   socket.on('message', function(message) {
        var data = message.data;
        var modelClass = store.modelFor(data.modelName);
        var serializer = store.serializerFor(data.modelName);
        var json = serializer.normalizeSingleResponse(store, modelClass, data, data.id);
         store.push(normalized);
      });
   ```
   @method normalizeResponse
   @param {DS.Store} store
   @param {DS.Model} primaryModelClass
   @param {Object} payload
   @param {String|Number} id
   @param {String} requestType
   @return {Object} JSON-API Document
   */
  normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.status && payload.status === 402) {
      this.session.invalidate();
      return payload;
    } else {
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  },

  /**
   Normalizes a part of the JSON payload returned by
   the server. You should override this method, munge the hash
   and call super if you have generic normalization to do.
   It takes the type of the record that is being normalized
   (as a DS.Model class), the property where the hash was
   originally found, and the hash to normalize.
   You can use this method, for example, to normalize underscored keys to camelized
   or other general-purpose normalizations.
   Example
   ```app/serializers/application.js
   import DS from 'ember-data';
   export default DS.JSONSerializer.extend({
        normalize: function(typeClass, hash) {
          var fields = Ember.get(typeClass, 'fields');
          fields.forEach(function(field) {
            var payloadField = Ember.String.underscore(field);
            if (field === payloadField) { return; }
             hash[field] = hash[payloadField];
            delete hash[payloadField];
          });
          return this._super.apply(this, arguments);
        }
      });
   ```
   @method normalize
   @param {DS.Model} typeClass
   @param {Object} hash
   @return {Object}

  normalize: function normalize(modelClass, resourceHash) {
    var data = null;

    if (resourceHash) {
      this.normalizeUsingDeclaredMapping(modelClass, resourceHash);

      data = {
        id: this.extractId(modelClass, resourceHash),
        type: modelClass.modelName,
        attributes: this.extractAttributes(modelClass, resourceHash),
        relationships: this.extractRelationships(modelClass, resourceHash)
      };

      this.applyTransforms(modelClass, data.attributes);
    }

    return { data: data };
  },

  extractRelationship: function extractRelationship(relationshipModelName, relationshipHash) {
    var relationship = this._super.apply(this, arguments);
    var relationshipModel = this.store.modelFor(relationshipModelName);
    return this.normalize(relationshipModel, relationship).data;
  },
   */



  /**
   @method normalizeArrayResponse
   @param {DS.Store} store
   @param {DS.Model} primaryModelClass
   @param {Object} payload
   @param {String|Number} id
   @param {String} requestType
   @return {Object} JSON-API Document
   */
  normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    var data = payload;
    if (payload.data) {
      data = payload.data;
      if (payload.meta) {
        data.meta = payload.meta;
      }
    }
    return this._normalizeResponse(store, primaryModelClass, data, id, requestType, false);
  },

  /**
   `keyForAttribute` can be used to define rules for how to convert an
   attribute name in your model to a key in your JSON.

   Example

   ```app/serializers/application.js
   import DS from 'ember-data';

   export default DS.RESTSerializer.extend({
     keyForAttribute: function(attr, method) {
       return Ember.String.underscorevar get = Ember.get;(attr).toUpperCase();
     }
   });
   ```

   @method keyForAttribute
   @param {String} key
   @param {String} method
   @return {String} normalized key
   */
  keyForAttribute(key /*, method */) {
    return underscore(key);
  },

  /**
   `keyForRelationship` can be used to define a custom key when
   serializing and deserializing relationship properties. By default
   `JSONSerializer` does not provide an implementation of this method.
   Example
   ```app/serializers/post.js
   import DS from 'ember-data';
   export default DS.JSONSerializer.extend({
        keyForRelationship: function(key, relationship, method) {
          return Ember.String.underscore(key);
        }
      });
   ```
   @method keyForRelationship
   @param {String} key
   @param {String} typeClass
   @param {String} method
   @return {String} normalized key
   */
  keyForRelationship: function keyForRelationship(key, relationshipKind, method) {
    key = underscore(key);
    if (relationshipKind === 'belongsTo') {
      key = key + "_id";
    } else if (relationshipKind === 'hasMany' && method === 'serialize') {
      key = key + "_attributes";
    }
    return key;
  },

  /**
   `keyForLink` can be used to define a custom key when deserializing link
   properties.
   @method keyForLink
   @param {String} key
   @param {String} kind `belongsTo` or `hasMany`
   @return {String} normalized key
   */
  keyForLink: function keyForLink(key /*, kind */) {
    return underscore(key);
  },

  /**
   Serialize `hasMany` relationship when it is configured as embedded objects.

   This example of a post model has many comments:

   ```js
   Post = DS.Model.extend({
      title:    DS.attr('string'),
      body:     DS.attr('string'),
      comments: DS.hasMany('comment')
    });

   Comment = DS.Model.extend({
      body:     DS.attr('string'),
      post:     DS.belongsTo('post')
    });
   ```

   Use a custom (type) serializer for the post model to configure embedded comments

   ```app/serializers/post.js
   import DS from 'ember-data;

   export default DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        comments: { embedded: 'always' }
      }
    })
   ```

   A payload with an attribute configured for embedded records can serialize
   the records together under the root attribute's payload:

   ```js
   {
     "post": {
       "id": "1"
       "title": "Rails is omakase",
       "body": "I want this for my ORM, I want that for my template language..."
       "comments": [{
         "id": "1",
         "body": "Rails is unagi"
       }, {
         "id": "2",
         "body": "Omakase O_o"
       }]
     }
   }
   ```

   The attrs options object can use more specific instruction for extracting and
   serializing. When serializing, an option to embed `ids` or `records` can be set.
   When extracting the only option is `records`.

   So `{ embedded: 'always' }` is shorthand for:
   `{ serialize: 'records', deserialize: 'records' }`

   To embed the `ids` for a related object (using a hasMany relationship):

   ```app/serializers/post.js
   import DS from 'ember-data;

   export default DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        comments: { serialize: 'ids', deserialize: 'records' }
      }
    })
   ```

   ```js
   {
     "post": {
       "id": "1"
       "title": "Rails is omakase",
       "body": "I want this for my ORM, I want that for my template language..."
       "comments": ["1", "2"]
     }
   }
   ```

   @method serializeHasMany
   @param {DS.Snapshot} snapshot
   @param {Object} json
   @param {Object} relationship
   */
  serializeHasMany(snapshot, json, relationship) {
    var attr = relationship.key;
    if (!this.hasSerializeRailsOption(attr)) {
      this._super(snapshot, json, relationship);
      return;
    }
    this._serializeEmbeddedHasManyRails(snapshot, json, relationship);
  },

  _serializeEmbeddedHasManyRails(snapshot, json, relationship) {
    var serializedKey = this._getMappedKey(relationship.key, snapshot.type);
    if (serializedKey === relationship.key && this.keyForRelationship) {
      serializedKey = this.keyForRelationship(relationship.key, relationship.kind, "serialize");
    }

    warn(
      `The embedded relationship '${serializedKey}' is undefined for '${snapshot.modelName}' with id '${snapshot.id}'. Please include it in your original payload.`,
      typeOf(snapshot.hasMany(relationship.key)) !== 'undefined',
      { id: 'ds.serializer.embedded-relationship-undefined' }
    );

    json[serializedKey] = this._generateSerializedHasManyRails(snapshot, relationship);
  },

  /*
   Returns an array of embedded records serialized to JSON
   */
  _generateSerializedHasManyRails(snapshot, relationship) {
    let relations = snapshot.record.get(relationship.key);
    let ret = [];

    relations.forEach(function(embedded) {
      var dirtyType = embedded.get('dirtyType');
      if (dirtyType === 'updated') {
        let embeddedJson = embedded.serialize({ includeId: true });
        ret.push(embeddedJson);
      } else if (dirtyType === 'created') {
        let embeddedJson = embedded.serialize({ includeId: true });
        ret.push(embeddedJson);
        //embedded.destroyRecord();
      } else if (dirtyType === 'deleted') {
        ret.push({ "id": embedded.get('id'), '_destroy': true });
      } else if (dirtyType !== undefined) {
        warn(`The dirtyType '${dirtyType}' is not handled for '${snapshot.modelName}' with id '${snapshot.id}'.`);
      }
    }, this);

    return ret;
  },

  // checks config for attrs option to serialize ids
  hasSerializeRailsOption(attr) {
    var option = this.attrsOption(attr);
    return option && option.serialize === 'rails';
  }
});
