import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  store: service(),

  isSelected: computed('sample', 'sampleAttribute', 'attributeValue', {
    get(/* key */) {
      var sampleAttribute = this.sampleAttribute;
      return sampleAttribute && !sampleAttribute.get('isDeleted');
    },
    set(key, value) {
      var sampleAttribute = this.sampleAttribute;
      if (value) {
        if (sampleAttribute) {
          if (sampleAttribute && sampleAttribute.get('isDeleted')) {
            sampleAttribute.rollbackAttributes();
          }
        }
        else {
          var store = this.store;
          var sample = this.sample;
          sampleAttribute = store.createRecord('sample-attribute');
          sampleAttribute.set('sample', sample);
          sampleAttribute.set('attributeValue', this.attributeValue);
          this.set('sampleAttribute', sampleAttribute);
        }
      } else {
        if (sampleAttribute) {
          var wasNew = sampleAttribute.get('isNew');
          if (wasNew) {
            this.set('sampleAttribute', undefined);
            sampleAttribute.destroyRecord();
          } else {
            sampleAttribute.deleteRecord();
          }
        }
      }
      return value;
    }
  })
});
