import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: 'li',
  attributeBindings: ['aria-labelledby', 'role', 'type'],
  classNameBindings: ['isActive:active', 'disabled'],
  seperator: '…',
  selected: null,

  url: computed('urlTemplate', 'page', function () {
    var urlTemplate = this.urlTemplate;
    var current = this.page;

    urlTemplate = urlTemplate.replace('{current}', current);

    return urlTemplate;
  }),

  isActive: computed('page', 'selected', function () {
    try {
      return this.page === Number(this.selected);
    } catch(e) {
      return false;
    }
  }),

  isDots: computed('page', function () {
    var seperator = this.seperator;
    var page = this.page;

    return page === seperator;
  }),

  actions: {
    select: function () {
      let last = this.selected;
      let page = this.page;

      if (page !== last) {
        this.pageSet(page, last);
      }
    }
  }
});
