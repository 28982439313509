import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import TextFragment from "mikrobi-responsive/models/text-fragment";

class TextFragmentForm extends FormDataProxy<TextFragment> {}

export default class AdminTextFragmentController extends Controller {

  @tracked declare formData: TextFragmentForm;

  prepareFormData(model: TextFragment) {
    this.formData = new TextFragmentForm(model);
  }

  @action
  save() {
    let self = this;
    this.formData.save(false);
    return this.model.save().then((model: TextFragment) => {
      return self.store.findRecord('text-fragment', model.id, { reload: true })
    })
      .then((model: TextFragment) => {
        self.formData = new TextFragmentForm(model);
      });
  }

  @action
  delete() {
    let contr = this;
    this.model.destroyRecord().then(function(/* response */) {
      contr.transitionToRoute('admin.text-fragments');
    });
  }
}
