import Service, { inject as service } from '@ember/service';

export default class PermissionService extends Service {
  @service declare session: any;

  hasRole(roleNames: String[]) {
    let userRoles: any[] = this.session.data.authenticated.user.user_roles;
    return userRoles.any((ur: any) => {
      return roleNames.indexOf(ur.role.name) > -1;
    });
  }

  isSuperAdministrator() {
    return this.hasRole(["superadministrator"]);
  }
}

declare module '@ember/service' {
  interface Registry {
    'permissions': PermissionService;
  }
}
