import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend ({

  store: service(),

  _initialValue: undefined,

  actions: {
    select(occurrenceValue) {
      this.set('groupValue', occurrenceValue);
    }
  },

  groupValue: computed('occurrence', 'occurrence.occurrenceValue', {
    get(/* key */) {
      return this.get('occurrence.occurrenceValue');
    },
    set(key, occurrenceValue) {
      if (occurrenceValue === null) {
        occurrenceValue = undefined;
      }
      let occurrence = this.occurrence;
      if (occurrence === undefined && occurrenceValue !== undefined) {
        let store = this.store;
        let sample = this.sample;
        let organism = this.organism;
        occurrence = store.createRecord('organism-occurrence');
        occurrence.set('sample', sample);
        occurrence.set('microOrganism', organism);
        this.set('occurrence', occurrence);
      }

      if (occurrence !== undefined) {
        if (occurrence.get('dirtyType')==='deleted') {
          occurrence.rollbackAttributes();
        }
        occurrence.set('occurrenceValue', occurrenceValue);

        if (this._initialValue === undefined) {
          if (occurrenceValue === undefined) {
            occurrence.destroyRecord();
          }
        } else if (occurrenceValue !== this._initialValue) {
          if (occurrenceValue === undefined) {
            occurrence.deleteRecord();
          } else {
            occurrence.set('_changed', true);
          }
        } else if (occurrence.get('dirtyType')!=='created'){
          occurrence.rollbackAttributes();
        }
      }
      return occurrenceValue;
    }
  }),

  occurrence: computed('sample.{organismOccurrences,organismOccurrences.@each}', 'organism', function() {
    let organismId = this.get('organism.id');
    let occurrence = this.get('sample.organismOccurrences').findBy('microOrganism.id', organismId);
    return occurrence;
  }),

  didReceiveAttrs() {
    let organismId = this.get('organism.id');
    let occurrence = this.get('sample.organismOccurrences').findBy('microOrganism.id', organismId);
    if (occurrence) {
      let self = this;
      occurrence.get('occurrenceValue').then(function(occurrenceValue) {
        self._initialValue = occurrenceValue;
        self.set('groupValue', occurrenceValue);
      });
    } else {
      this._initialValue = undefined;
    }
  }
});
