import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { FormDataProxy } from "mikrobi-responsive/types/forms/formDataProxy";
import Parameter from "mikrobi-responsive/models/parameter";
import { action } from "@ember/object";
import ParameterValue from "mikrobi-responsive/models/parameter-value";

class ParameterForm extends FormDataProxy<Parameter> {
  @tracked parameterValues: Array<ParameterValueForm>;

  constructor(model: Parameter) {
    super(model, undefined);

    this.parameterValues = model.parameterValues.map(function(item) {
      return new ParameterValueForm(item);
    });

    this.registerChildren(this.parameterValues);
  }

  addParameterValue(parameterValue: ParameterValueForm): void {
    this.registerChild(parameterValue);
    this.parameterValues.addObject(parameterValue);
  }
}

class ParameterValueForm extends FormDataProxy<ParameterValue> {

  constructor(model: ParameterValue) {
    super(model, undefined);
  }

  get colorButtonColor(): string {
    return this._props['color'] || this._model.color;
  }

  get colorButtonStyle() {
    if (this.colorButtonColor) {
      return "background-color: #" + this.colorButtonColor;
    } else {
      return "";
    }
  }
}

export default class AdminParameterController extends Controller {

  @tracked declare formData: ParameterForm;

  prepareFormData(model: Parameter) {
    this.formData = new ParameterForm(model);
  }

  @action
  save() {
    const self = this;
    const wasNew = this.model.isNew;
    return this.formData.save(true).then((parameter: Parameter) => {
      self.formData.clear();
      if (wasNew) {
        self.transitionToRoute('admin.parameters.parameter', parameter.id);
      } else {
        self.model.reload().then((parameter: Parameter) => {
          self.formData = new ParameterForm(parameter);
        })
      }
    });
  }

  @action
  createParameterValue() {
    const parameterValue: ParameterValue = this.store.createRecord('parameter-value');
    parameterValue.parameter = this.model;
    parameterValue.index = this.model.parameterValues.length;
    this.formData.addParameterValue(new ParameterValueForm(parameterValue));
  }

  @action
  deleteParameterValue(parameterValueForm: ParameterValueForm) {
    parameterValueForm.delete();
    parameterValueForm.setDirty();
  }

  @action
  delete() {
    let contr = this;
    this.model.destroyRecord().then(function(/* response */) {
      contr.transitionToRoute('admin.parameters');
    });
  }
}
