import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import OccurrenceType from "mikrobi-responsive/models/occurrence-type";
import Phylum from "mikrobi-responsive/models/phylum";
import DS from "ember-data";
import MediumLink from "mikrobi-responsive/models/medium-link";

export default class MicroOrganism extends Model {
  @attr('date') declare updated_at: Date;
  @attr declare description: string;
  @attr('boolean') declare common: boolean;
  @attr declare indicator: boolean;
  @attr declare name: string;
  @attr('number') declare saprobie: number;
  @attr('number') declare weight: number;
  @attr('number') declare row: number;
  @attr('date') declare created_at: Date;
  @belongsTo('phylum') declare phylum: Phylum;
  @belongsTo('occurrence-type') declare occurrenceType: OccurrenceType;
  @hasMany('medium-link') declare mediumLinks: DS.PromiseArray<MediumLink>;
}
