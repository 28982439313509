import Model, { attr, hasMany } from '@ember-data/model';
import DS from 'ember-data';
import ParameterValue from 'mikrobi-responsive/models/parameter-value';

export default class Parameter extends Model {
  @attr() declare description: string;
  @attr('boolean') declare num: boolean;
  @attr() declare name: string;
  @hasMany('parameter-value') declare parameterValues: DS.PromiseArray<ParameterValue>;
}
