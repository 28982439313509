import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  session: service(),

  authenticator: 'authenticator:custom',

  actions: {
    authenticate: function() {
      var credentials = this.getProperties('clientshort', 'identification', 'password');
      this.session.authenticate('authenticator:custom', credentials).catch((message) => {
        this.set('errorMessage', message);
      });
    },
    authenticationSucceeded: function() {
    }
  }
});
