import Model, { attr, belongsTo } from '@ember-data/model';

export default class MediumLink extends Model {
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('number') index;
  @attr linkType;
  @attr linkId;
  @belongsTo('medium', { inverse: null }) medium;
  @attr mediumId;
}
